// Basic.js
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSessionId } from "../../../Redux/action/sessionAction"; 

import MD5 from "crypto-js/md5";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// react-router-dom components
import axios from "axios";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { Button } from "@mui/material";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import logoImage from "assets/images/HMS.webp";

//Api
import { BASE_API_URL } from "api/Api";

function Login() {
  const dispatch = useDispatch();
  const [login, setLogin] = useState({
    userName: "",
    password: "",
  });
  const [errmsg, setErrmsg] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLogin((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleLogin = async (e) => {
    e.preventDefault();

    const hashedPassword = MD5(login.password).toString();

    const apiUrl = BASE_API_URL;

    const method = "login";
    const input_type = "JSON";
    const response_type = "JSON";
    const rest_data = encodeURIComponent(
      JSON.stringify({
        user_auth: {
          user_name: login.userName,
          password: hashedPassword,
        },
        name_value_list: [],
      })
    );
    const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;
    try {
      const response = await axios.get(fullUrl);

      // let session_id = response.data.id;
      // localStorage.setItem("ssid", session_id);

      if (response.status === 200 && response.data.id) {
        console.log("Login successful:", response.data);
        const session = dispatch(setSessionId(response.data.id));
        console.log(session);
        navigate("/");
        toast.success("Login success");
        
      } else {
        console.log("Login failed:", response.data);
        toast.error("login failed");
        setErrmsg("login failed");
      }
    } catch (error) {
      if (error.response) {
        console.error("An error occurred during login:", error.response.data);
        console.error("Status code:", error.response.status);
        toast.error("Login failed. Please check your credentials and try again.");
      } else if (error.request) {
        console.error("No Response received:", error.request);
        toast.error("No response from the server. Please try again later.");
      } else {
        console.error("Error setting up request:", error.message);
      }
    }
  };
  
  return (
    <BasicLayout>
      <Card>
        <MDBox>
          <MDTypography
            variant="h4"
            fontWeight="medium"
            color="white"
            mt={1}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <img src={logoImage} alt="veera" style={{ width: "150px", height: "140px" }} />
          </MDTypography>
        </MDBox>
        <form onSubmit={handleLogin}>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox role="form">
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Username"
                  onChange={handleChange}
                  value={login.userName}
                  name="userName"
                  id="userName"
                  fullWidth
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Password"
                  onChange={handleChange}
                  value={login.password}
                  name="password"
                  id="password"
                  fullWidth
                />
              </MDBox>
              <MDBox mt={4} mb={1}>
                <Button
                  type="submit"
                  variant="gradient"
                  sx={{
                    backgroundColor: "#012034",
                    color: "#ffffff",
                    width: "100%",
                    padding: "6px 8px", // Increase padding for a larger button
                    fontSize: "1rem",
                    "&:hover": {
                      backgroundColor: "#012034", // Slightly lighter shade for hover effect
                    },
                    "&:active": {
                      backgroundColor: "#012034", // Maintain the same color on click
                    },
                  }}
                >
                  sign in
                </Button>
              </MDBox>
              {errmsg && (
                <MDBox mt={2}>
                  <MDTypography
                    variant="subtitle2"
                    color="error"
                    align="center"
                  >
                    {errmsg}
                  </MDTypography>
                </MDBox>
              )}
            <ToastContainer position="top-right" />
            </MDBox>
          </MDBox>
        </form>
      </Card>
    </BasicLayout>
  );
}

export default Login;
