import React, { useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import logoImage from "assets/images/HMS.webp";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import jsPDF from "jspdf";
import "jspdf-autotable";

function Invoice() {
  const invoiceRef = useRef();
  const [toDetails, setToDetails] = useState({
    name: "",
    address: "",
    email: "",
    phone: "",
    city: "",
    id: "",
  });

  const invoiceDatas = [
    {
      id: 1,
      name: "Vicky",
      date: "2024-07-01",
      phoneNumber: "9790675433",
      city: "Devakottai",
      serviceName: "X-Ray",
      amount: 100,
      hospitality: "Hospital A",
      totalAmount: 200,
    },
    {
      id: 2,
      name: "Jane Smith",
      date: "2024-07-02",
      phoneNumber: "9245672902",
      city: "Trichy",
      serviceName: "Physiotherapy",
      amount: 150,
      hospitality: "Hospital B",
      totalAmount: 300,
    },
    {
      id: 3,
      name: "Praveen",
      date: "2024-07-03",
      phoneNumber: "9368291127",
      city: "Madurai",
      serviceName: "Consultation",
      amount: 200,
      hospitality: "Hospital C",
      totalAmount: 400,
    },
    {
      id: 4,
      name: "Karthik",
      date: "2024-07-04",
      phoneNumber: "9790618699",
      city: "Rs.Mangalam",
      serviceName: "Lab Tests",
      amount: 250,
      hospitality: "Hospital D",
      totalAmount: 500,
    },
    {
      id: 5,
      name: "Carol White",
      date: "2024-07-05",
      phoneNumber: "8722964439",
      city: "Chennai",
      serviceName: "Surgery",
      amount: 300,
      hospitality: "Hospital E",
      totalAmount: 600,
    },
    {
      id: 6,
      name: "Karan",
      date: "2024-07-06",
      phoneNumber: "9883445623",
      city: "Ramanathapuram",
      serviceName: "Dental Care",
      amount: 350,
      hospitality: "Hospital F",
      totalAmount: 700,
    },
    {
      id: 7,
      name: "Emily Clark",
      date: "2024-07-07",
      phoneNumber: "9885334210",
      city: "Devakottai",
      serviceName: "Vaccination",
      amount: 400,
      hospitality: "Hospital G",
      totalAmount: 800,
    },
    {
      id: 8,
      name: "Ragu",
      date: "2024-07-08",
      phoneNumber: "8965537289",
      city: "Annanagar",
      serviceName: "MRI Scan",
      amount: 450,
      hospitality: "Hospital H",
      totalAmount: 900,
    },
    {
      id: 9,
      name: "Grace Lewis",
      date: "2024-07-09",
      phoneNumber: "9090388213",
      city: "Dindugal",
      serviceName: "Blood Tests",
      amount: 500,
      hospitality: "Hospital I",
      totalAmount: 1000,
    },
    {
      id: 10,
      name: "Aravind",
      date: "2024-07-10",
      phoneNumber: "9663729192",
      city: "Kovai",
      serviceName: "Eye Checkup",
      amount: 550,
      hospitality: "Hospital J",
      totalAmount: 1100,
    },
  ];

  const [invoiceData, setInvoiceData] = useState({
    number: "",
    date: "",
  });

  const handlePhoneChange = (event) => {
    const { value } = event.target;
    setToDetails((prevDetails) => ({ ...prevDetails, phone: value }));

    const matchedData = invoiceDatas.find((item) => item.phoneNumber === value);
    if (matchedData) {
      setToDetails((prevDetails) => ({
        ...prevDetails,
        name: matchedData.name,
        city: matchedData.city,
        phone: matchedData.phoneNumber,
        id: matchedData.id,
        date: matchedData.date,
        serviceName: matchedData.serviceName,
        hospitality: matchedData.hospitality,
        amount: matchedData.amount,
        totalAmount: matchedData.totalAmount,
      }));
    } else {
      setToDetails((prevDetails) => ({
        ...prevDetails,
        name: "",
        city: "",
        id: "",
        date: "",
        serviceName: "",
        hospitality: "",
        amount: "",
        totalAmount: "",
      }));

      invoiceDatas.push({
        id: invoiceDatas.length + 1,
        name: "",
        city: "",
        phoneNumber: value,
        email: "",
        invoiceDate: new Date().toISOString().split("T")[0],
        totalAmount: 0,
        status: "Pending",
        uhid: "",
        doctorName: "",
      });
    }
  };

  const handleIdChange = (event) => {
    const { value } = event.target;
    setToDetails((prevDetails) => ({ ...prevDetails, id: value }));

    const matchedData = invoiceDatas.find((item) => item.id.toString() === value);
    if (matchedData) {
      setToDetails((prevDetails) => ({
        ...prevDetails,
        name: matchedData.name,
        city: matchedData.city,
        phone: matchedData.phoneNumber,
        id: matchedData.id,
        date: matchedData.date,
        serviceName: matchedData.serviceName,
        hospitality: matchedData.hospitality,
        amount: matchedData.amount,
        totalAmount: matchedData.totalAmount,
      }));
    } else {
      setToDetails((prevDetails) => ({
        ...prevDetails,
        name: "",
        city: "",
        phone: "",
        email: "",
        date: "",
        serviceName: "",
        hospitality: "",
        amount: "",
        totalAmount: "",
      }));

      invoiceDatas.push({
        id: value,
        name: "",
        city: "",
        phoneNumber: "",
        email: "",
        invoiceDate: new Date().toISOString().split("T")[0],
        totalAmount: 0,
        status: "Pending",
        uhid: "",
        doctorName: "",
      });
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name in invoiceData) {
      setInvoiceData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else if (name.includes("-")) {
      const [itemIndex, fieldName] = name.split("-");
      setItems((prevItems) => {
        const newItems = [...prevItems];

        if (newItems[itemIndex]) {
          newItems[itemIndex][fieldName] = value;
        } else {
          console.error(`Item at index ${itemIndex} does not exist.`);
        }

        return newItems;
      });
    }

    setToDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const [items, setItems] = useState([
    { serviceName: "", hospitality: "", amount: "", totalAmount: "" },
  ]);
  const [savedItems, setSavedItems] = useState([]);
  const [showAddItemForm, setShowAddItemForm] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);

  const handleSave = () => {
    setSavedItems(items);
    setItems([{ serviceName: "", hospitality: "", amount: "", totalAmount: "" }]);
    setShowAddItemForm(false);
  };

  const handleDeleteItem = (index) => {
    setSavedItems((prevItems) => prevItems.filter((_, i) => i !== index));
  };

  const handleEditItem = (index) => {
    alert(`Edit item at index ${index}`);
  };

  const handlePreview = () => {
    setOpenPreviewModal(true);
  };
  const handleDownload = () => {
    const doc = new jsPDF();
    const logoWidth = 50;
    const logoHeight = 40;
    const logoX = 150;
    const logoY = 10;
    const backgroundPadding = 20;

    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    doc.text(`Invoice Number: ${invoiceData.number}`, 10, 20);
    doc.text(`Date: ${invoiceData.date}`, 10, 30);

    doc.setFillColor(255, 255, 255);
    doc.rect(
      logoX - backgroundPadding,
      logoY - backgroundPadding,
      logoWidth + 2 * backgroundPadding,
      logoHeight + 2 * backgroundPadding,
      "F"
    ); // Draw background rectangle with padding

    doc.addImage(logoImage, logoX, logoY, logoWidth, logoHeight);

    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("From:", 10, 50);
    doc.setFont("helvetica", "normal");
    doc.text("VeeraHospital", 10, 60);
    doc.text("01, Silambani Sannathi Street, inside Arch Devakottai 630 302", 10, 70);
    doc.text("Email: veerahospital@gmail.com", 10, 80);
    doc.text("Phone-No: 983455675345", 10, 90);

    doc.setFont("helvetica", "bold");
    doc.text("To:", 10, 110);
    doc.setFont("helvetica", "normal");
    doc.text(`Name: ${toDetails.name}`, 10, 120);
    doc.text(`City: ${toDetails.city}`, 10, 130);
    doc.text(`ID: ${toDetails.id}`, 10, 140);
    doc.text(`Phone-No: ${toDetails.phone}`, 10, 150);

    const startY = 170;

    doc.autoTable({
      startY: startY,
      head: [["S.No", "Date", "Service Name", "Hospitality", "Amount", "Total Amount"]],
      body: savedItems.map((item, index) => [
        index + 1,
        item.date,
        item.serviceName,
        item.hospitality,
        item.amount,
        item.totalAmount,
      ]),
      theme: "striped",
      margin: { left: 10, right: 10 },
      headStyles: { fillColor: [102, 44, 144] },
      styles: { fontSize: 10, cellPadding: 3 },
      columnStyles: {
        0: { cellWidth: "auto" },
        1: { cellWidth: "auto" },
        2: { cellWidth: "auto" },
        3: { cellWidth: "auto" },
        4: { cellWidth: "auto" },
        5: { cellWidth: "auto" },
        6: { cellWidth: "auto" },
      },
    });

    doc.setFontSize(10);
    doc.setFont("helvetica", "italic");
    doc.text("Thank you for your Hospital!", 10, doc.internal.pageSize.height - 20);

    doc.save(`invoice_${invoiceData.number}.pdf`);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={14}>
            <Card>
              <Box p={4} ref={invoiceRef}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                  <Box display="flex" alignItems="center" flexWrap="wrap">
                    <Box mr={2}>
                      <TextField
                        label="Invoice Number"
                        name="number"
                        size="small"
                        value={invoiceData.number}
                        onChange={handleChange}
                        variant="outlined"
                        margin="normal"
                        sx={{ borderRadius: "18px", width: "190px" }}
                      />
                    </Box>
                    <Box>
                      <TextField
                        label="Date"
                        name="date"
                        type="date"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={invoiceData.date}
                        onChange={handleChange}
                        variant="outlined"
                        margin="normal"
                        sx={{ borderRadius: "18px", width: "190px" }}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <img
                      src={logoImage}
                      alt="Invoice Logo"
                      style={{ width: "150px", float: "right" }}
                    />
                  </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" mb={3}>
                  <Box
                    border={1}
                    borderColor="grey.400"
                    borderRadius="18px"
                    p={3}
                    width="48%"
                    bgcolor="#ffffff"
                  >
                    <Typography variant="h6" fontWeight="bold" mb={1}>
                      From
                    </Typography>
                    <Typography variant="body1" fontWeight="bold" gutterBottom>
                      VeeraHospital
                    </Typography>
                    <Typography variant="body2" fontWeight="normal" gutterBottom>
                      01, Silambani Sannathi Street, inside Arch Devakottai 630 302
                    </Typography>
                    <Typography variant="body2" fontWeight="normal" gutterBottom>
                      <strong>Email:</strong> veerahospital@gmail.com
                    </Typography>
                    <Typography variant="body2" fontWeight="normal">
                      <strong>Phone-No:</strong> 983455675345
                    </Typography>
                  </Box>
                  <Box
                    border={1}
                    borderColor="grey.400"
                    borderRadius="18px"
                    p={3}
                    width="48%"
                    bgcolor="#ffffff"
                  >
                    <Typography variant="h6" fontWeight="bold">
                      To
                    </Typography>
                    <div>
                      <TextField
                        label="ID"
                        name="id"
                        value={toDetails.id}
                        onChange={handleIdChange}
                        fullWidth
                        margin="normal"
                        sx={{
                          borderRadius: "15px",
                          width: "275px",
                          backgroundColor: "#D8F5EE",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "15px",
                            height: "45px",
                          },
                        }}
                      />
                      <TextField
                        label="Patient Name"
                        name="name"
                        value={toDetails.name}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        sx={{
                          borderRadius: "15px",
                          width: "275px",
                          backgroundColor: "#D8F5EE",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "15px",
                            height: "45px",
                          },
                        }}
                      />
                      <TextField
                        label="City"
                        name="city"
                        value={toDetails.city}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        sx={{
                          borderRadius: "15px",
                          width: "275px",
                          backgroundColor: "#D8F5EE",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "15px",
                            height: "45px",
                          },
                        }}
                      />
                      <TextField
                        label="Phone Number"
                        name="phone"
                        value={toDetails.phone}
                        onChange={handlePhoneChange}
                        fullWidth
                        margin="normal"
                        sx={{
                          borderRadius: "15px",
                          width: "275px",
                          backgroundColor: "#D8F5EE",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "15px",
                            height: "45px",
                          },
                        }}
                      />
                    </div>
                  </Box>
                </Box>
                {showAddItemForm && (
                  <Box mt={3}>
                    {items.map((item, index) => (
                      <Box
                        key={index}
                        mb={2}
                        p={2}
                        border={1}
                        borderColor="grey.300"
                        borderRadius="8px"
                        bgcolor="#F9F9F9"
                      >
                        <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={2}>
                          <TextField
                            label="Date"
                            name={`${index}-date`}
                            type="date"
                            value={item.date}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            sx={{
                              borderRadius: "18px",
                              backgroundColor: "#D8F5EE",
                              "& .MuiOutlinedInput-root": { borderRadius: "18px", height: "45px" },
                            }}
                          />
                          <TextField
                            label="Service Name"
                            name={`${index}-serviceName`}
                            value={item.serviceName}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            sx={{
                              borderRadius: "18px",
                              backgroundColor: "#D8F5EE",
                              "& .MuiOutlinedInput-root": { borderRadius: "18px", height: "45px" },
                            }}
                          />
                          <TextField
                            label="Hospitality"
                            name={`${index}-hospitality`}
                            value={item.hospitality}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            sx={{
                              borderRadius: "18px",
                              backgroundColor: "#D8F5EE",
                              "& .MuiOutlinedInput-root": { borderRadius: "18px", height: "45px" },
                            }}
                          />
                          <TextField
                            label="Amount"
                            name={`${index}-amount`}
                            type="number"
                            value={item.amount}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            sx={{
                              borderRadius: "18px",
                              backgroundColor: "#D8F5EE",
                              "& .MuiOutlinedInput-root": { borderRadius: "18px", height: "45px" },
                            }}
                          />
                          <TextField
                            label="TotalAmount"
                            name={`${index}-totalAmount`}
                            type="number"
                            value={item.totalAmount}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            sx={{
                              borderRadius: "18px",
                              backgroundColor: "#D8F5EE",
                              "& .MuiOutlinedInput-root": { borderRadius: "18px", height: "45px" },
                            }}
                          />
                        </Box>
                      </Box>
                    ))}
                    <Button
                      variant="gradient"
                      color="primary"
                      onClick={handleSave}
                      sx={{
                        mt: 2,
                        borderRadius: "18px",
                        color: "#FFFFFF",
                        backgroundColor: "#662C90",
                        padding: "9px",
                        marginRight: "492px",
                        "&:hover": {
                          backgroundColor: "#5a257e",
                        },
                      }}
                    >
                      Save Items
                    </Button>
                  </Box>
                )}

                <Box mt={3}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(7, 1fr)"
                    gap={2}
                    bgcolor="#662C90"
                    borderRadius="8px"
                    p={1}
                    mb={3}
                  >
                    <Typography variant="h6" fontWeight="bold" style={{ color: "#ffffff" }}>
                      S.NO
                    </Typography>
                    <Typography variant="h6" fontWeight="bold" style={{ color: "#ffffff" }}>
                      Date
                    </Typography>
                    <Typography variant="h6" fontWeight="bold" style={{ color: "#ffffff" }}>
                      Service
                    </Typography>
                    <Typography variant="h6" fontWeight="bold" style={{ color: "#ffffff" }}>
                      Hospitality
                    </Typography>
                    <Typography variant="h6" fontWeight="bold" style={{ color: "#ffffff" }}>
                      Amount
                    </Typography>
                    <Typography variant="h6" fontWeight="bold" style={{ color: "#ffffff" }}>
                      Total Amount
                    </Typography>
                  </Box>

                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(7, 1fr)"
                    gap={2}
                    p={2}
                    borderBottom={1}
                    borderColor="grey.300"
                  >
                    {toDetails.city || toDetails.id ? (
                      <Typography variant="body1" sx={{ marginRight: "20px" }}>
                        1
                      </Typography>
                    ) : null}

                    <Typography variant="body1">{toDetails.date}</Typography>

                    <Typography variant="body1">{toDetails.serviceName}</Typography>

                    <Typography variant="body1">{toDetails.hospitality}</Typography>

                    <Typography variant="body1">{toDetails.amount}</Typography>

                    <Typography variant="body1">{toDetails.totalAmount}</Typography>
                  </Box>

                  {savedItems.map((item, index) => (
                    <Box
                      key={index}
                      display="grid"
                      gridTemplateColumns="repeat(7, 1fr)"
                      gap={2}
                      p={2}
                      borderBottom={1}
                      borderColor="grey.300"
                    >
                      <Typography>{index + 2}</Typography>
                      <Typography>{item.date}</Typography>
                      <Typography>{item.serviceName}</Typography>
                      <Typography>{item.hospitality}</Typography>
                      <Typography>{item.amount}</Typography>
                      <Typography>{item.totalAmount}</Typography>
                      <Box display="flex" justifyContent="flex-start" gap={1}>
                        <Button
                          onClick={() => handleEditItem(index)}
                          sx={{ minWidth: "auto", padding: "4px" }}
                        >
                          <EditIcon />
                        </Button>
                        <Button
                          onClick={() => handleDeleteItem(index)}
                          sx={{ minWidth: "auto", padding: "4px" }}
                        >
                          <DeleteIcon />
                        </Button>
                      </Box>
                    </Box>
                  ))}
                </Box>

                <Box mt={3} display="flex" justifyContent="space-between">
                  <Button
                    variant="gradient"
                    color="primary"
                    onClick={handlePreview}
                    sx={{
                      color: "#FFFFFF",
                      borderRadius: "18px",
                      backgroundColor: "#662C90",
                      padding: "9px",
                      marginRight: "492px",
                      "&:hover": {
                        backgroundColor: "#5a257e",
                      },
                    }}
                  >
                    Preview Invoice
                  </Button>
                  {showAddItemForm ? (
                    <Button
                      variant="gradient"
                      color="secondary"
                      onClick={() => setShowAddItemForm(false)}
                      sx={{
                        borderRadius: "18px",
                        color: "#FFFFFF",
                        backgroundColor: "#F44336",
                        padding: "10px",
                        marginleft: "492px",
                        "&:hover": {
                          backgroundColor: "#F44336",
                        },
                      }}
                    >
                      Cancel
                    </Button>
                  ) : (
                    <Button
                      variant="gradient"
                      color="primary"
                      onClick={() => setShowAddItemForm(true)}
                      sx={{
                        borderRadius: "18px",
                        color: "#FFFFFF",
                        backgroundColor: "#662C90",
                        padding: "10px",
                        marginleft: "492px",
                        "&:hover": {
                          backgroundColor: "#5a257e",
                        },
                      }}
                    >
                      Add Item
                    </Button>
                  )}
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box
        mt={1}
        p={-1}
        border={2}
        borderColor="white"
        borderRadius="18px"
        bgcolor="#FFFFFF"
        position="relative"
      >
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{
            position: "absolute",
            top: -10,
            left: 10,
            bgcolor: "#FFFFFF",
            px: 1,
            borderRadius: "18px",
            fontSize: "1rem",
          }}
        >
          Notes:
        </Typography>
        <Box mt={2} p={1}>
          <Typography variant="body2" sx={{ fontSize: "0.895rem", fontWeight: "bold" }}>
            All accounts are to be paid within 7 days from receipt of invoice. To be paid by cheque
            or credit card or direct payment online.
          </Typography>
        </Box>
      </Box>
      <Dialog
        open={openPreviewModal}
        onClose={() => setOpenPreviewModal(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            padding: "25px",
            backgroundColor: "#f5f5f5",
            color: "#662C90",
            borderBottom: "1px solid #e0e0e0",
            fontSize: "1.5rem",
            fontWeight: "bold",
            borderRadius: "8px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          Preview Invoice
          <Box
            component="img"
            sx={{
              height: 70,
            }}
            alt="Veera Logo"
            src={logoImage}
          />
        </DialogTitle>
        <DialogContent>
          <Box p={3}>
            <Typography variant="h6" fontWeight="bold">
              Invoice Number:{" "}
              <Typography variant="body2" component="span" fontWeight="normal">
                {invoiceData.number}
              </Typography>
            </Typography>
            <Typography variant="h6" fontWeight="bold">
              Date:{" "}
              <Typography variant="body2" component="span" fontWeight="normal">
                {invoiceData.date}
              </Typography>
            </Typography>
            <Box display="flex" justifyContent="space-between" mb={4} mt={3}>
              <Box>
                <Typography variant="h6" fontWeight="bold">
                  From
                </Typography>
                <Typography variant="h5" fontWeight="bold">
                  VeeraHospital
                </Typography>
                <Typography variant="body3">
                  01, Silambani Sannathi Street, inside Arch Devakottai 630 302
                </Typography>
                <Typography variant="h6">
                  <strong>Email:</strong>{" "}
                  <Typography variant="body2" component="span">
                    veerahospital@gmail.com
                  </Typography>
                </Typography>
                <Typography variant="h6">
                  <strong>Phone-No:</strong>{" "}
                  <Typography variant="body2" component="span">
                    983455675345
                  </Typography>
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6" fontWeight="bold">
                  To
                </Typography>
                <Typography variant="body1">{toDetails.name}</Typography>
                <Typography variant="body2">
                  <strong>City:</strong> {toDetails.city}
                </Typography>
                <Typography variant="body2">
                  <strong>ID:</strong> {toDetails.id}
                </Typography>
                <Typography variant="body2">
                  <strong>Phone-No:</strong> {toDetails.phone}
                </Typography>
              </Box>
            </Box>
            <Box mt={4}>
              <Box
                display="grid"
                gridTemplateColumns="repeat(6, 1fr)"
                gap={1.5}
                bgcolor="#662C90"
                borderRadius="8px"
                p={-2}
                mb={2}
              >
                <Typography variant="h6" fontWeight="bold" style={{ color: "#ffffff" }}>
                  S.NO
                </Typography>
                <Typography variant="h6" fontWeight="bold" style={{ color: "#ffffff" }}>
                  Date
                </Typography>
                <Typography variant="h6" fontWeight="bold" style={{ color: "#ffffff" }}>
                  Service{" "}
                </Typography>
                <Typography variant="h6" fontWeight="bold" style={{ color: "#ffffff" }}>
                  Hospitality
                </Typography>
                <Typography variant="h6" fontWeight="bold" style={{ color: "#ffffff" }}>
                  Amount
                </Typography>
                <Typography variant="h6" fontWeight="bold" style={{ color: "#ffffff" }}>
                  Total Amount
                </Typography>
              </Box>
              {savedItems.map((item, index) => (
                <Box
                  key={index}
                  display="grid"
                  gridTemplateColumns="repeat(6, 1fr)"
                  gap={2}
                  p={1}
                  borderBottom={1}
                  borderColor="grey.300"
                >
                  <Typography>{index + 1}</Typography>
                  <Typography>{item.date}</Typography>
                  <Typography>{item.serviceName}</Typography>
                  <Typography>{item.hospitality}</Typography>
                  <Typography>{item.amount}</Typography>
                  <Typography>{item.totalAmount}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="gradient"
            color="primary"
            onClick={handleDownload}
            sx={{
              ml: 2,
              color: "#ffff",
              backgroundColor: "#662C90",
              padding: "7px",
              borderRadius: "12px",
              marginRight: "492px",
              "&:hover": {
                backgroundColor: "#5a257e",
              },
            }}
          >
            Download Invoice
          </Button>
          <Button
            onClick={() => setOpenPreviewModal(false)}
            color="primary"
            style={{ color: "#662C90" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Invoice;
