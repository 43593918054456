import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import MDInput from "components/MDInput";
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { initialDoctors } from "layouts/doctors/doctors";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { BASE_API_URL } from "api/Api";

function PatientsRegistration() {
  const doctors = initialDoctors;

  const [patientType, setPatientType] = useState('Out Patient');
  const [uhid, setUhid] = useState('');
  const [name, setName] = useState('');
  const [age, setAge] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [doctor, setDoctor] = useState('');
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const [selectedGender, setSelectedGender] = useState(''); 

const handleGenderChange = (event) => {
  setSelectedGender(event.target.value);
};

// Gender options
const genderOptions = ['Male', 'Female', 'Other'];

const handleDoctorChange = (event) => {
  const { value } = event.target;
  setSelectedDoctors(typeof value === 'string' ? value.split(',') : value);
};

  const navigate = useNavigate();

  const sessionId = useSelector((state) => state.session.sessionId);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    console.log(uhid, name, age, selectedGender, phone,email, city, country, selectedDoctors, patientType)
    const apiUrl = BASE_API_URL;

    const method = "set_entry";
    const input_type = "JSON";
    const response_type = "JSON";
    const rest_data = JSON.stringify({
      session: sessionId,
      module_name: "Contacts",
      name_value_list: [
        { name: "uhid_c", value: uhid },
        { name: "pateintname_c", value: name },
        { name: "email1", value: email },
        { name: "patient_age_c", value: age },
        { name: "patient_gender_c", value: selectedGender },
        { name: "phone_mobile", value: phone },
        { name: "patienttype_c", value: patientType },
        { name: "city_c", value: city },
        { name: "state_c", value: country },
        { name: "doc_c", value: selectedDoctors.join(', ') },
        { name: "servicesnames_c", value: "" },

      ]
    });

    const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

    try {
      const response = await axios.post(fullUrl);

      if (response.status === 200 && response.data.id) {
        if (patientType === 'In Patient') {
          navigate('/inpatients');
        } else {
          navigate('/outpatients');
        }
        console.log("Patient registered successfully:", response.data);
      } else {
        console.log("Register failed:", response.data);
        toast.error("Register failed");
      }
    } catch (error) {
      if (error.response) {
        console.error("An error occurred during registration:", error.response.data);
        console.error("Status code:", error.response.status);
        toast.error("Registration failed. Please check your credentials and try again.");
      } else if (error.request) {
        console.error("No Response received:", error.request);
        toast.error("No response from the server. Please try again later.");
      } else {
        console.error("Error setting up request:", error.message);
      }
    }
  };

  const handlePatientTypeChange = (event) => {
    setPatientType(event.target.value);
  };

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <form onSubmit={handleFormSubmit} >
          <div className="flex justify-center mt-8">

            <div className="w-2/4">
              <div className="flex flex-col justify-center items-center gap-4">
                <div className="w-3/4 pr-1 mb-4">
                  <MDInput 
                    id="uhid" 
                    name="uhid" 
                    label="UHID" 
                    fullWidth 
                    value={uhid}
                    required
                    onChange={(e) => setUhid(e.target.value)}
                  />
                </div>
                <div className="w-3/4 pr-1 mb-4">
                  <MDInput 
                    id="name" 
                    name="name" 
                    label="Name" 
                    fullWidth 
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="w-3/4 pr-1 mb-4 text-gray-500">
                <label className="block text-sm font-medium text-gray-500 mb-2">Gender</label>
                <Select
                value={selectedGender}
                onChange={handleGenderChange}
                displayEmpty
                required
                fullWidth
                className='p-3 '
                renderValue={(selected) => (selected ? selected : 'Select Gender')}
              >
                {genderOptions.map((gender) => (
                  <MenuItem key={gender} value={gender}>
                    {gender}
                  </MenuItem>
                ))}
              </Select>
                </div>
                <div className="w-3/4 pr-1 mb-4">
                  <MDInput 
                    id="age" 
                    name="age" 
                    label="Age" 
                    fullWidth 
                    value={age}
                    onChange={(e) => setAge(e.target.value)}
                  />
                </div>
                <div className="w-3/4 pr-1 mb-4">
                  <MDInput 
                    id="phone" 
                    name="phone" 
                    label="Phone No" 
                    fullWidth
                    required 
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="w-2/4">
              <div className="flex flex-col justify-center items-center gap-4">
                <div className="w-3/4 pr-1 mb-4">
                  <MDInput 
                    id="email" 
                    name="email" 
                    label="Email Id" 
                    fullWidth 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="w-3/4 pr-1 mb-4">
                  <MDInput 
                    id="city" 
                    name="city" 
                    label="City" 
                    fullWidth 
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
                <div className="w-3/4 pr-1 mb-4">
                  <MDInput 
                    id="country" 
                    name="country" 
                    label="State" 
                    fullWidth 
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </div>
                <div className="w-3/4 pr-1 mb-3">
                  <label className="block text-sm font-medium text-gray-500 mb-2">Patient Type</label>
                  <div className="flex justify-around">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="op"
                      name="patientType"
                      value="Out Patient"
                      checked={patientType === 'Out Patient'}
                      onChange={handlePatientTypeChange}
                      className="h-4 w-4 text-gray-500 focus:ring-indigo-500 cursor-pointer border-gray-300"
                    />
                    <label htmlFor="op" className="ml-2 text-sm cursor-pointer text-gray-500">
                      OP (Out Patient)
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="ip"
                      name="patientType"
                      value="In Patient"
                      checked={patientType === 'In Patient'}
                      onChange={handlePatientTypeChange}
                      className="h-4 w-4 text-gray-500 focus:ring-indigo-500 cursor-pointer border-gray-300"
                    />
                    <label htmlFor="ip" className="ml-2 text-sm cursor-pointer text-gray-500">
                      IP (In Patient)
                    </label>
                  </div>
                  </div>
                </div>

                <div className="w-3/4 pr-1 mb-4">
                  <label htmlFor="multiSelect" className="block text-sm font-medium text-gray-500 mb-2">
                    Select Doctor
                  </label>
                  <Select
                  displayEmpty
                  multiple
                  value={selectedDoctors}
                  onChange={handleDoctorChange}
                  renderValue={(selected) => selected.length === 0 ? 'Select Doctor' : selected.join(', ')}
                  fullWidth
                  className="p-3"
                  MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
                >
                  {doctors.map((doctor) => (
                    <MenuItem key={doctor.id} value={doctor.name}>
                      <Checkbox checked={selectedDoctors.indexOf(doctor.name) > -1} />
                      {doctor.name}
                    </MenuItem>
                  ))}
                </Select>
                </div>

              </div>
            </div>
          </div>

          <div className="mt-6 text-center">
            <MDButton type="submit" variant="gradient" color="info">
              Add Patient
            </MDButton>
          </div>
          <ToastContainer position="top-right" />
        </form>
      </DashboardLayout>
    </div>
  )
}

export default PatientsRegistration;
