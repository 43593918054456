import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Paper from "@mui/material/Paper";
import { Grid, Card, Box, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import jsPDF from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import { useRef } from "react";
import html2canvas from "html2canvas";

const initialInvoiceData = [
  {
    id: 1,
    invoiceNumber: "UHID001",
    city: "Devakottai",
    patientName: "VeeraPrabhu",
    admissionDate: "2024-07-01",
    dischargeDate: "2024-07-05",
    phone: "9826777832",
    patientType: "OP",
    address: "31 A Roja Street,Devakottai",
    state: "Ramanathapuram",
    country: "India",
    patientservice: "Blood Test,X-ray",
    servicecharge: "3105",
    totalAmount: "3105",
    bednumber: "101",
  },
  {
    id: 2,
    invoiceNumber: "UHID002",
    city: "Paramakudi",
    patientName: "VeeraPandi",
    admissionDate: "2024-07-05",
    dischargeDate: "2024-07-10",
    phone: "9876543210",
    patientType: "IP",
    address: "7-G Rainbow colony Street,Paramakudi",
    state: "Ramanathapuram",
    country: "India",
    patientservice: "ECG",
    servicecharge: "900",
    totalAmount: "900",
    bednumber: "101",
  },
  {
    id: 3,
    invoiceNumber: "UHID003",
    city: "Sivakasi",
    patientName: "Gopal",
    admissionDate: "2024-07-10",
    dischargeDate: "2024-07-15",
    phone: "9551234567",
    patientType: "OP",
    address: "551 A krishna Street,Sivakasi",
    state: "Sivakasi",
    country: "India",
    patientservice: "Urine Test,X-ray",
    servicecharge: "3305",
    totalAmount: "3305",
    bednumber: "101",
  },
  {
    id: 4,
    invoiceNumber: "UHID004",
    city: "Rsmangalam",
    patientName: "Karthik",
    admissionDate: "2024-07-12",
    dischargeDate: "2024-07-20",
    phone: "8659876543",
    patientType: "IP",
    address: "211 F Padapadi Street,Ramanathapuram",
    state: "Ramanathapuram",
    country: "India",
    patientservice: "Blood Test,X-ray",
    servicecharge: "3105",
    totalAmount: "3105",
    bednumber: "101",
  },
  {
    id: 5,
    invoiceNumber: "UHID005",
    city: "K.K.Nagar",
    patientName: "Vishnu",
    admissionDate: "2024-07-15",
    dischargeDate: "2024-07-22",
    phone: "5556781234",
    patientType: "OP",
    address: "551 A krishna Street,Chennai",
    state: "Chennai",
    country: "India",
    patientservice: "X-ray",
    servicecharge: "1105",
    totalAmount: "1105",
    bednumber: "101",
  },
];

const Dischargesummary = () => {
  const navigate = useNavigate();
  const [invoices, setInvoices] = useState(initialInvoiceData);
  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentPatient, setCurrentPatient] = useState(null);
  const [newPatient, setNewPatient] = useState({
    id: "",
    invoiceNumber: "",
    city: "",
    patientName: "",
    admissionDate: "",
    dischargeDate: "",
    phone: "",
    patientType: "OP",
    address: "",
    state: "",
    country: "",
    patientservice: "",
    servicecharge: "",
    totalAmount: "",
    bednumber: "",
  });

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "ID",
      selector: (row) => (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleDischargeSummaryOpen(row);
          }}
          style={{
            color: "blue",
            cursor: "pointer",
          }}
        >
          {row.invoiceNumber}
        </a>
      ),
      sortable: true,
    },
    {
      name: "Patient Name",
      selector: (row) => row.patientName,
      sortable: true,
    },
    {
      name: "City",
      selector: (row) => row.city,
      sortable: true,
    },
    {
      name: "Admission Date",
      selector: (row) => row.admissionDate,
      sortable: true,
    },
    {
      name: "Discharge Date",
      selector: (row) => row.dischargeDate,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Patient Type",
      selector: (row) => row.patientType,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <IconButton onClick={() => handleEditPatientOpen(row)} sx={{ color: "#3f51b5" }}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(row.id)} sx={{ color: "red" }}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredInvoices = invoices.filter((invoice) =>
    invoice.patientName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAddPatientOpen = () => {
    navigate("/dischargesummary/addsummary");
  };
  const handleEditPatientOpen = () => {
    navigate("/dischargesummary/editsummary");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewPatient({ ...newPatient, [name]: value });
  };

  const handleSave = () => {
    if (editMode) {
      setInvoices(
        invoices.map((invoice) => (invoice.id === currentPatient.id ? newPatient : invoice))
      );
    } else {
      setInvoices([
        ...invoices,
        {
          ...newPatient,
          id: invoices.length + 1,
          invoiceNumber: `UHID00${invoices.length + 1}`,
        },
      ]);
    }
    handleClose();
  };

  const handleDelete = (id) => {
    setInvoices(invoices.filter((invoice) => invoice.id !== id));
  };
  const [dischargeSummary, setDischargeSummary] = useState(null);
  const [dischargeSummaryOpen, setDischargeSummaryOpen] = useState(false);
  const handleDischargeSummaryOpen = (invoice) => {
    setDischargeSummary(invoice);
    setDischargeSummaryOpen(true);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={4}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={3}
                mt={-3}
                py={2}
                px={2}
                sx={{
                  background: "linear-gradient(45deg, #712c8c,#de157a)",
                  borderRadius: "12px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <MDTypography variant="h6" color="white">
                  Discharge Summary
                </MDTypography>
                <MDBox display="flex" gap={2}>
                  <div>
                    <MDInput
                      label="Search"
                      value={searchTerm}
                      onChange={handleSearch}
                      InputProps={{
                        sx: {
                          color: "#ffffff",
                        },
                      }}
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: "#ffffff",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#ffffff",
                          },
                          "&:hover fieldset": {
                            borderColor: "#ffffff",
                          },
                        },
                      }}
                    />
                  </div>
                  <Button
                    variant="gradient"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleAddPatientOpen}
                    sx={{
                      backgroundColor: "#ffffff",
                      color: "secondary",
                      "&:hover": {
                        backgroundColor: "#ffffff",
                      },
                    }}
                  >
                    Discharge Summary
                  </Button>
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  columns={columns}
                  data={filteredInvoices}
                  highlightOnHover
                  pagination
                  customStyles={{
                    rows: {
                      style: {
                        minHeight: "60px",
                      },
                    },
                    headCells: {
                      style: {
                        paddingLeft: "12px",
                        paddingRight: "12px",
                        fontWeight: "bold",
                      },
                    },
                    cells: {
                      style: {
                        paddingLeft: "12px",
                        paddingRight: "12px",
                      },
                    },
                  }}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Dialog
        open={dischargeSummaryOpen}
        onClose={() => setDischargeSummaryOpen(false)}
        maxWidth="md"
      >
        <DialogTitle style={{ textAlign: "center", fontWeight: "bold", fontSize: "1.5rem" }}>
          Discharge Summary
        </DialogTitle>
        <DialogContent>
          {dischargeSummary && (
            <Box>
              {/* Patient Details */}
              <Box
                style={{
                  backgroundColor: "#5a257e",
                  padding: "5px",
                  borderRadius: "8px",
                  marginBottom: "15px",
                }}
              >
                <Typography
                  variant="h6"
                  style={{ textAlign: "center", color: "white", fontWeight: "bold" }}
                >
                  Patient Details
                </Typography>
              </Box>
              <Grid container spacing={3} style={{ marginBottom: "15px" }}>
                <Grid item xs={6}>
                  <Typography variant="body1" style={{ fontWeight: "bold", color: "black" }}>
                    Patient Name:
                  </Typography>
                  <Typography variant="body1" style={{ marginBottom: "5px" }}>
                    {dischargeSummary.patientName}
                  </Typography>
                  <Typography variant="body1" style={{ fontWeight: "bold", color: "black" }}>
                    Discharge Date:
                  </Typography>
                  <Typography variant="body1" style={{ marginBottom: "5px" }}>
                    {dischargeSummary.dischargeDate}
                  </Typography>
                  <Typography variant="body1" style={{ fontWeight: "bold", color: "black" }}>
                    Patient Type:
                  </Typography>
                  <Typography variant="body1">{dischargeSummary.patientType}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" style={{ fontWeight: "bold", color: "black" }}>
                    Admission Date:
                  </Typography>
                  <Typography variant="body1" style={{ marginBottom: "5px" }}>
                    {dischargeSummary.admissionDate}
                  </Typography>
                  <Typography variant="body1" style={{ fontWeight: "bold", color: "black" }}>
                    Phone Number:
                  </Typography>
                  <Typography variant="body1" style={{ marginBottom: "5px" }}>
                    {dischargeSummary.phone}
                  </Typography>
                </Grid>
              </Grid>

              <Box
                style={{
                  backgroundColor: "#5a257e",
                  padding: "5px",
                  borderRadius: "8px",
                  marginBottom: "15px",
                }}
              >
                <Typography
                  variant="h6"
                  style={{ textAlign: "center", color: "white", fontWeight: "bold" }}
                >
                  Discharge Summary
                </Typography>
              </Box>
              <Typography
                variant="body1"
                component="div"
                style={{ whiteSpace: "pre-line", marginBottom: "15px" }}
              >
                <strong style={{ color: "black" }}>Diagnosis:</strong> Crush Injury F4 DPX-Left
                Hand-PALMAR OBLIQUE AMPUTATION OF FINGER TIP
                {"\n"}
                <strong style={{ color: "black" }}>Presenting complaints:</strong>
                {"\n"}Complaints of pain with over the Left F4. Alleged history of RTA Falls the
                bike near Alagappa Park Around 7.15PM. Patient came to hospital with bleeding
                finger.
                {"\n"}
                <strong style={{ color: "black" }}>Past History:</strong> Not a Known case of DM/ HT
                / CAD / CKD / Asthma / Drug Allergy
                {"\n"}
                <strong style={{ color: "black" }}>General Examination:</strong>
                {"\n"}Patient conscious, oriented, afebrile
                {"\n"}
                <strong style={{ color: "black" }}>BP:</strong> 120/80mmhg{" "}
                <strong style={{ color: "black" }}>PR:</strong> 80/min
                {"\n"}
                <strong style={{ color: "black" }}>TEMP:</strong> 98 F{" "}
                <strong style={{ color: "black" }}>RR:</strong> 22/min
                {"\n"}
                <strong style={{ color: "black" }}>CVS:</strong> S1 S2 +{"\n"}
                <strong style={{ color: "black" }}>R/S:</strong> BAE +{"\n"}
                <strong style={{ color: "black" }}>P/A:</strong> Soft
                {"\n"}
                <strong style={{ color: "black" }}>CNC:</strong> NFND
                {"\n"}
                <strong style={{ color: "black" }}>Local Examination:</strong>
                {"\n"}Left F4 - Crush with total amputation of left F4 DPX level
                {"\n"}- Rom Painful & Restricted
                {"\n"}
                <strong style={{ color: "black" }}>Investigations:</strong>
                {"\n"}Blood Investigations – Enclosed
                {"\n"}
                <strong style={{ color: "black" }}>Xray:</strong> PARTIAL AMPUTEE DPX F4
                {"\n"}
                <strong style={{ color: "black" }}>Concern:</strong> Plastic surgery procedure
                explained in details
                {"\n"}• Cross Finger Flap
                {"\n"}• Release
                {"\n"}Explained in details about the Procedure Functional Limitations About the
                Finger.
                {"\n"}
                <strong style={{ color: "black" }}>Procedure:</strong> Cross Finger Flap.
                {"\n"}SAP, Parts painted & drapped, primary wound debridement done, cross finger
                flap planned in reverence and flap elevated and inset with 3-0 ethylon. Secondary
                raw area conceded with SSG Dressing done.
                {"\n"}
                <strong style={{ color: "black" }}>Advice At Discharge:</strong>
                {"\n"}T.Chymoral Forte Ds 1-0-1
                {"\n"}T.Pandove 1-0-1
                {"\n"}T.Zincovit 1-0-0
                {"\n"}T.Aceclo Plus 1-0-1
                {"\n"}Inj.Piptem 4.5m IV Bd
              </Typography>

              <Box
                style={{
                  backgroundColor: "#5a257e",
                  padding: "5px",
                  borderRadius: "8px",
                  marginBottom: "15px",
                }}
              >
                <Typography
                  variant="h6"
                  style={{ textAlign: "center", color: "white", fontWeight: "bold" }}
                >
                  Patient Address
                </Typography>
              </Box>
              <Grid container spacing={3} style={{ marginBottom: "15px" }}>
                <Grid item xs={6}>
                  <Typography variant="body1" style={{ fontWeight: "bold", color: "black" }}>
                    Patient Name:
                  </Typography>
                  <Typography variant="body1" style={{ marginBottom: "5px" }}>
                    {dischargeSummary.patientName}
                  </Typography>
                  <Typography variant="body1" style={{ fontWeight: "bold", color: "black" }}>
                    City:
                  </Typography>
                  <Typography variant="body1" style={{ marginBottom: "5px" }}>
                    {dischargeSummary.city}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" style={{ fontWeight: "bold", color: "black" }}>
                    Address:
                  </Typography>
                  <Typography variant="body1" style={{ marginBottom: "5px" }}>
                    {dischargeSummary.address}
                  </Typography>
                  <Typography variant="body1" style={{ fontWeight: "bold", color: "black" }}>
                    State:
                  </Typography>
                  <Typography variant="body1" style={{ marginBottom: "5px" }}>
                    {dischargeSummary.state}
                  </Typography>
                  <Typography variant="body1" style={{ fontWeight: "bold", color: "black" }}>
                    Country:
                  </Typography>
                  <Typography variant="body1">{dischargeSummary.country}</Typography>
                </Grid>
              </Grid>

              <Box
                style={{
                  backgroundColor: "#5a257e",
                  padding: "5px",
                  borderRadius: "8px",
                  marginBottom: "15px",
                }}
              >
                <Typography
                  variant="h6"
                  style={{ textAlign: "center", color: "white", fontWeight: "bold" }}
                >
                  Service And Hospitality Details
                </Typography>
              </Box>
              <Grid container spacing={3} style={{ marginBottom: "15px" }}>
                <Grid item xs={6}>
                  <Typography variant="body1" style={{ fontWeight: "bold", color: "black" }}>
                    Patient Service:
                  </Typography>
                  <Typography variant="body1" style={{ marginBottom: "5px" }}>
                    {dischargeSummary.patientservice}
                  </Typography>
                  <Typography variant="body1" style={{ fontWeight: "bold", color: "black" }}>
                    Service Charge:
                  </Typography>
                  <Typography variant="body1" style={{ marginBottom: "5px" }}>
                    {dischargeSummary.servicecharge}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" style={{ fontWeight: "bold", color: "black" }}>
                    Bed Number:
                  </Typography>
                  <Typography variant="body1" style={{ marginBottom: "5px" }}>
                    {dischargeSummary.bednumber}
                  </Typography>
                  <Typography variant="body1" style={{ fontWeight: "bold", color: "black" }}>
                    Total Amount:
                  </Typography>
                  <Typography variant="body1">{dischargeSummary.totalAmount}</Typography>
                </Grid>
              </Grid>
            </Box>
          )}
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </DashboardLayout>
  );
};

export default Dischargesummary;
