import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import Modal from '@mui/material/Modal';
import { Icon } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import data from 'data.json'; 
import { BASE_API_URL } from "api/Api";
import axios from "axios";
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const OutPatientsLists = () => {

  const [outPatients, setOutPatients] = useState([]);
  const [editingOutPatient, setEditingOutPatient] = useState(null);
  const [viewingOutPatient, setViewingOutPatient] = useState(null);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [openServiceModal, setOpenServiceModal] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [availableServices, setAvailableServices] = useState([]);

  const navigate = useNavigate();

  const sessionId = useSelector((state) => state.session.sessionId);

  useEffect(() => {
    const fetchOutPatients = async () => {
      const apiUrl = BASE_API_URL;
      const method = "get_entry_list";
      const input_type = "JSON";
      const response_type = "JSON";
      const rest_data = JSON.stringify({
        session: sessionId,
        module_name: "Contacts",
      });

      const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

      try {
        const response = await axios.get(fullUrl);
        console.log("API Response:", response.data);

        const getPatientsData = () => {
          if (response.data && response.data.entry_list) { // Check for response.data instead of response
            return response.data.entry_list.map(entry => {
              const data = entry.name_value_list;
              return {
                id: entry.id,
                uhid: data.uhid_c.value,
                name: data.pateintname_c.value,
                gender: data.patient_gender_c.value,
                patientType: data.patienttype_c.value,
                age: data.patient_age_c.value,
                email: data.email1.value,
                phone: data.phone_mobile.value,
                city: data.city_c.value,
                state: data.state_c.value,
                country: data.patient_country_name_c.value,
                admissionDate: data.admissiondate_c.value,
                dischargedDate: data.dischargeddate_c.value,
                service: data.servicesnames_c.value,
                servicecost: data.servicecost_c.value,
                fees: data.totalcost_c.value,
                doctor: data.doc_c.value,
              };
            })
            .filter(patient => patient.patientType === "Out Patient");
          }
          return [];
        };

        const outPatientsData = getPatientsData();
        setOutPatients(outPatientsData); 

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (sessionId) {
      fetchOutPatients();
    }
  }, [sessionId]);

  const handleDeletePatient = async (patientId) => {

    const apiUrl = `${BASE_API_URL}`;
        const method = "set_entry";
        const input_type = "JSON";
        const response_type = "JSON";

        const rest_data = JSON.stringify({
          session: sessionId,
          module_name: "Contacts",
          name_value_list: {
            id: patientId,
            deleted: { name: "deleted", value: "1" },
        }
        });

        const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

        try {
            const response = await axios.post(fullUrl);
            if (response.status === 200 && response.data.id) {
              console.log("InPatient deleted successfully:", response.data);
              toast.success("Patient deleted Successfully");
              const updatedOutPatient = outPatients.filter((patient)=>patient.id !== patientId);
              setOutPatients(updatedOutPatient)

          } else {
              console.error("Patient delete failed:", response.data);
              toast.error("Patient delete Failed")
          }
      } catch (error) {
              console.error("Error during Patient Delete:", error.message);
          }
      };

  const handleEditOutPatient = (outPatient) => {
    navigate(`/editoutpatient/${outPatient.id}`);
  };

  const handleCloseServiceModal = () => {
    setOpenServiceModal(false);
  };

  useEffect(() => {
    const { services } = data;
    setAvailableServices(services || []);
  }, []);

  const handleServiceButtonClick = (outPatient) => {
    setEditingOutPatient(outPatient);
    setSelectedServices(outPatient.service ? outPatient.service.split(', ') : []);
    setOpenServiceModal(true);
  };

  const handleSaveSelectedServices = async (patientId) => {

    const updatedOutPatient = {
      id: patientId,
      services: selectedServices,
    }
    
    // API call to save the updated inpatient data
    const apiUrl = `${BASE_API_URL}`;
    const method = "set_entry";
    const input_type = "JSON";
    const response_type = "JSON";
  

    const rest_data = JSON.stringify({
        session: sessionId,
        module_name: "Contacts",
        name_value_list: {
          id: updatedOutPatient.id,
          servicesnames_c: updatedOutPatient.services.join(', '),
        }
      });

      const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

      try {
          const response = await axios.post(fullUrl);

          if (response.status === 200 && response.data.id) {
              console.log("Service updated successfully:", response.data);
              toast.success("Service updated Successfully")
              setOutPatients((prevPatients) =>
                prevPatients.map((patient) =>
                  patient.id === editingOutPatient.id
                    ? { ...patient, service: updatedOutPatient.services.join(', ') }
                    : patient
                )
              );
          } else {
              console.error("Service update failed:", response.data);
              toast.error("Service Update Failed")
          }
      } catch (error) {
          if (error.response) {
              console.error("Error during Service update:", error.response.data);
          } else {
              console.error("Error setting up request:", error.message);
          }
      }

    setOpenServiceModal(false);
  };


  const handleViewOutPatient = (outPatient) => {
    setViewingOutPatient(outPatient);
    setOpenViewModal(true);
  };

  const handleCloseViewModal = () => {
    setViewingOutPatient(null);
    setOpenViewModal(false);
  };

  const handleEditButtonClickInViewModal = () => {
    if (viewingOutPatient) {
      handleEditOutPatient(viewingOutPatient);
      setOpenViewModal(false);
    }
  };

  const filteredOutPatients = outPatients.filter((outPatient) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      outPatient.uhid.toLowerCase().includes(searchLower) ||
      outPatient.name.toLowerCase().includes(searchLower) ||
      outPatient.phone.toLowerCase().includes(searchLower) ||
      outPatient.city.toLowerCase().includes(searchLower) ||
      outPatient.age.toString().includes(searchLower)
    );
  });

  const columns = [
    { Header: '#', accessor: 'serialNo', width: '5%', align: 'center' },
    { Header: 'UHID', accessor: 'uhid', width: '15%', align: 'center' },
    { Header: 'Name', accessor: 'name', width: '20%', align: 'left' },
    { Header: 'Age', accessor: 'age', width: '10%', align: 'center' },
    { Header: 'Phone Number', accessor: 'phoneNumber', width: '15%', align: 'left' },
    { Header: 'City', accessor: 'city', width: '15%', align: 'center' },
    { Header: 'Fees', accessor: 'fees', width: '15%', align: 'center' },
    { Header: 'Services', accessor: 'services', width: '15%', align: 'center' },
    { Header: 'Action', accessor: 'action', align: 'center' },
  ];

  const rows = filteredOutPatients.map((outPatient, index) => ({
    serialNo: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {index + 1}
      </MDTypography>
    ),
    uhid: (
      <MDTypography
        component="button"
        variant="caption"
        color="text"
        fontWeight="medium"
        onClick={() => handleViewOutPatient(outPatient)}
      >
        {outPatient.uhid}
      </MDTypography>
    ),
    name: (
      <MDTypography
        component="button"
        variant="caption"
        color="text"
        fontWeight="medium"
        onClick={() => handleViewOutPatient(outPatient)}
      >
        {outPatient.name}
      </MDTypography>
    ),
    age: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {outPatient.age}
      </MDTypography>
    ),
    phoneNumber: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {outPatient.phone}
      </MDTypography>
    ),
    city: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {outPatient.city}
      </MDTypography>
    ),
    fees: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {outPatient.fees? outPatient.fees : "No fees added"}
      </MDTypography>
    ),
      services: (
      <MDTypography
      component="span"
      variant="caption"
      color="text"
      fontWeight="medium"
      sx={{ whiteSpace: 'pre-line', lineHeight: 1.5 }}
    >
    {outPatient.service? outPatient.service : "No services added"}
    </MDTypography>
    ),
    action: (
      <MDBox display="flex" gap={2}>
       <MDTypography
          component="button"
          variant="caption"
          color="success"
          fontWeight="medium"
          onClick={() => handleServiceButtonClick(outPatient)}
        >
          <Icon fontSize="small">medical_services</Icon>
        </MDTypography>
        <MDTypography
          component="button"
          variant="caption"
          color="info"
          fontWeight="medium"
          onClick={() => handleEditOutPatient(outPatient)}
        >
          <Icon fontSize="small">edit</Icon>
        </MDTypography>
        <MDTypography
          component="button"
          variant="caption"
          color="error"
          fontWeight="medium"
          onClick={() => handleDeletePatient(outPatient.id)}
        >
          <Icon fontSize="small">delete</Icon>
        </MDTypography>
      </MDBox>
    ),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={4}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={3}
                mt={-3}
                py={2}
                px={2}
                variant="gradient"
                bgColor="customGradient"
                borderRadius="lg"
                coloredShadow="#EA257C"
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <MDTypography variant="h6" color="white">
                  Out Patients
                </MDTypography>
                <MDBox display="flex" gap={2}>
                  <MDInput
                    label="Search"
                    className='text-white'
                    value={searchTerm}
                    inputProps={{
                      style: {
                        color: 'white', 
                      },
                    }}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <Link to="/patientsregistration">
                    <MDButton variant="contained" color="white">
                      Add OP
                    </MDButton>
                  </Link>
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* Service Modal */}
      <Modal 
      open={openServiceModal} 
      onClose={handleCloseServiceModal}
      aria-labelledby="service-modal-title"
      aria-describedby="service-modal-description"
        >
        <Card
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            p: 4,
          }}
        >
          <MDTypography variant="h4" mb={2} gutterBottom fontWeight="bold">
            Add Services
          </MDTypography>

          <Select
            displayEmpty
            multiple
            value={selectedServices}
            onChange={(e) => setSelectedServices(e.target.value)}
            renderValue={(selected) => (selected.length === 0 ? 'Select services' : selected.join(', '))}
            fullWidth
            className='p-3'
            MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
          >
            {availableServices.map((service) => (
                <MenuItem key={service.name} value={service.name}>
                  <Checkbox checked={selectedServices.indexOf(service.name) > -1} />
                  {service.name}
                </MenuItem>
              ))}
          </Select>

          <MDBox mt={3} display="flex" justifyContent="flex-end" gap={2}>
            <MDButton onClick={()=> handleSaveSelectedServices(editingOutPatient.id)} variant="contained" color="success">
              Save
            </MDButton>
            <MDButton 
            onClick={handleCloseServiceModal}  variant="contained" color="error">
              Cancel
            </MDButton>
          </MDBox>
        </Card>
      </Modal>

      {/* View Modal */}
      <Modal open={openViewModal} onClose={handleCloseViewModal}>
  <Card
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      p: 4,
    }}
  >
    {viewingOutPatient && (
      <>
        <MDTypography variant="h4" mb={2} gutterBottom fontWeight="bold">
          Patient Details
        </MDTypography>
        <MDTypography variant="body2" mb={1}>
          <strong>UHID:</strong> {viewingOutPatient.uhid}
        </MDTypography>
        <MDTypography variant="body2" mb={1}>
          <strong>Name:</strong> {viewingOutPatient.name}
        </MDTypography>
        <MDTypography variant="body2" mb={1}>
          <strong>Gender:</strong> {viewingOutPatient.gender}
        </MDTypography>
        <MDTypography variant="body2" mb={1}>
          <strong>Age:</strong> {viewingOutPatient.age}
        </MDTypography>
        <MDTypography variant="body2" mb={1}>
          <strong>Email</strong> {viewingOutPatient.email}
        </MDTypography>
        <MDTypography variant="body2" mb={1}>
          <strong>City</strong> {viewingOutPatient.city}
        </MDTypography>
        <MDTypography variant="body2" mb={1}>
          <strong>State</strong> {viewingOutPatient.state}
        </MDTypography>
        <MDTypography variant="body2" mb={1}>
          <strong>Doctors:</strong> {viewingOutPatient.doctor}
        </MDTypography>
        <MDTypography variant="body2" mb={1}>
          <strong>Services:</strong>
          {viewingOutPatient.service}
        </MDTypography>
        <MDTypography variant="body2" mb={1}>
          <strong>Fees</strong> {viewingOutPatient.fees}
        </MDTypography>
        <MDBox mt={2} display="flex" justifyContent="flex-end" gap={2}>
        <MDButton variant="contained"
            color="primary" onClick={handleCloseViewModal}>
              Close
            </MDButton>
          <MDButton
            variant="contained"
            color="info"
            onClick={handleEditButtonClickInViewModal}
          >
            Edit
          </MDButton>
        </MDBox>
      </>
    )}
  </Card>
</Modal>
<ToastContainer position="top-right" />
    </DashboardLayout>
  );
};

export default OutPatientsLists;
