import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import Modal from '@mui/material/Modal';
import { Icon } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import data from 'data.json'; 
import { BASE_API_URL } from "api/Api";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from 'react-redux';

const InPatientsLists = () => {

  const [editingInPatient, setEditingInPatient] = useState(null);
  const [viewingInPatient, setViewingInPatient] = useState(null);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [openServiceModal, setOpenServiceModal] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [inPatients, setInPatients] = useState([]); 
  const [availableServices, setAvailableServices] = useState([]);

  const navigate = useNavigate();
  const sessionId = useSelector((state) => state.session.sessionId);

  useEffect(() => {
    const fetchInPatients = async () => {
      const apiUrl = BASE_API_URL;
      const method = "get_entry_list";
      const input_type = "JSON";
      const response_type = "JSON";
      const rest_data = JSON.stringify({
        session: sessionId,
        module_name: "Contacts",
      });

      const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

      try {
        const response = await axios.get(fullUrl);
        console.log("API Response:", response.data);

        // Define and call the function to process patient data within the useEffect
        const getPatientsData = () => {
          if (response.data && response.data.entry_list) { // Check for response.data instead of response
            return response.data.entry_list.map(entry => {
              const data = entry.name_value_list;
              return {
                id: entry.id,
                uhid: data.uhid_c.value,
                name: data.pateintname_c.value,
                gender: data.patient_gender_c.value,
                patientType: data.patienttype_c.value,
                age: data.patient_age_c.value,
                email: data.email1.value,
                phone: data.phone_mobile.value,
                city: data.city_c.value,
                state: data.state_c.value,
                country: data.patient_country_name_c.value,
                admissionDate: data.admissiondate_c.value,
                dischargedDate: data.dischargeddate_c.value,
                service: data.servicesnames_c.value,
                serviceCost: data.servicecost_c.value,
                fees: data.totalcost_c.value,
                doctor: data.doc_c.value,
              };
            })
            .filter(patient => patient.patientType === "In Patient");
          }
          return [];
        };
        
        const inPatientsData = getPatientsData();
        setInPatients(inPatientsData); 

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (sessionId) {
      fetchInPatients();
    }
  }, [sessionId]);

  
  const handleDeletePatient = async (patientId) => {

    const apiUrl = `${BASE_API_URL}`;
        const method = "set_entry";
        const input_type = "JSON";
        const response_type = "JSON";

        const rest_data = JSON.stringify({
          session: sessionId,
          module_name: "Contacts",
          name_value_list: {
            id: patientId,
            deleted: { name: "deleted", value: "1" },
        }
        });

        const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

        try {
            const response = await axios.post(fullUrl);
            if (response.status === 200 && response.data.id) {
              console.log("InPatient deleted successfully:", response.data);
              toast.success("Patient deleted Successfully");
              const updatedInPatient = inPatients.filter((patient)=>patient.id !== patientId);
              setInPatients(updatedInPatient)

          } else {
              console.error("Patient delete failed:", response.data);
              toast.error("Patient delete Failed")
          }
      } catch (error) {
              console.error("Error during Patient Delete:", error.message);
          }
      };

  const handleEditInPatient = (inPatient) => {
    navigate(`/editinpatient/${inPatient.id}`);
  };

  const handleCloseServiceModal = () => {
    setOpenServiceModal(false);
  };

 

  useEffect(() => {
    const { services } = data;
    setAvailableServices(services || []);
  }, []);

  const handleServiceButtonClick = (inPatient) => {
    setEditingInPatient(inPatient);
    setSelectedServices(inPatient.service ? inPatient.service.split(', ') : []);
    setOpenServiceModal(true);
  };

  const handleSaveSelectedServices = async (patientId) => {

    const updatedInPatient = {
      id: patientId,
      services: selectedServices,
    }
    
    // API call to save the updated inpatient data
    const apiUrl = `${BASE_API_URL}`;
    const method = "set_entry";
    const input_type = "JSON";
    const response_type = "JSON";

    const rest_data = JSON.stringify({
        session: sessionId,
        module_name: "Contacts",
        name_value_list: {
          id: updatedInPatient.id,
          servicesnames_c: updatedInPatient.services.join(', '),
        }
      });

      const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

      try {
          const response = await axios.post(fullUrl);

          if (response.status === 200 && response.data.id) {
              console.log("Service updated successfully:", response.data);
              toast.success("Service updated Successfully")
              setInPatients((prevPatients) =>
                prevPatients.map((patient) =>
                  patient.id === editingInPatient.id
                    ? { ...patient, service: updatedInPatient.services.join(', ') }
                    : patient
                )
              );
          } else {
              console.error("Service update failed:", response.data);
              toast.error("Service Update Failed")
          }
      } catch (error) {
          if (error.response) {
              console.error("Error during Service update:", error.response.data);
          } else {
              console.error("Error setting up request:", error.message);
          }
      }

    setOpenServiceModal(false);
  };


  const handleViewInPatient = (inPatient) => {
    setViewingInPatient(inPatient);
    setOpenViewModal(true);
  };

  const handleCloseViewModal = () => {
    setViewingInPatient(null);
    setOpenViewModal(false);
  };

  const handleEditButtonClickInViewModal = () => {
    if (viewingInPatient) {
      handleEditInPatient(viewingInPatient);
      setOpenViewModal(false);
    }
  };

  const filteredInPatients = inPatients.filter((inPatient) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      inPatient.uhid.toLowerCase().includes(searchLower) ||
      inPatient.name.toLowerCase().includes(searchLower) ||
      inPatient.phone.toLowerCase().includes(searchLower) || 
      inPatient.city.toLowerCase().includes(searchLower) ||
      inPatient.age.toString().includes(searchLower)
    );
  });


  const columns = [
    { Header: '#', accessor: 'serialNo', width: '5%', align: 'center' },
    { Header: 'UHID', accessor: 'uhid', width: '15%', align: 'center' },
    { Header: 'Name', accessor: 'name', width: '20%', align: 'left' },
    { Header: 'Age', accessor: 'age', width: '10%', align: 'center' },
    { Header: 'Admission Date', accessor: 'admissionDate', width: '15%', align: 'center' },
    { Header: 'Discharge Date', accessor: 'dischargeDate', width: '15%', align: 'center' },
    { Header: 'Services', accessor: 'services', width: '15%', align: 'center' },
    { Header: 'Action', accessor: 'action', align: 'center' },
  ];

  const rows = filteredInPatients.map((inPatient, index) => ({
    serialNo: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {index + 1}
      </MDTypography>
    ),
    uhid: (
      <MDTypography
        component="button"
        variant="caption"
        color="text"
        fontWeight="medium"
        onClick={() => handleViewInPatient(inPatient)}
      >
        {inPatient.uhid}
      </MDTypography>
    ),
    name: (
      <MDTypography
        component="button"
        variant="caption"
        color="text"
        fontWeight="medium"
        onClick={() => handleViewInPatient(inPatient)}
      >
        {inPatient.name}
      </MDTypography>
    ),
    age: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {inPatient.age}
      </MDTypography>
    ),
    admissionDate: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {inPatient.admissionDate}
      </MDTypography>
    ),
    dischargeDate: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {inPatient.dischargedDate}
      </MDTypography>
    ),
    services: (
      <MDTypography
        component="span"
        variant="caption"
        color="text"
        fontWeight="medium"
        sx={{ whiteSpace: 'pre-line', lineHeight: 1.5 }}
      >
        {inPatient.service ? inPatient.service : "No services added"}
      </MDTypography>
    ),
    action: (
      <MDBox display="flex" gap={2}>
        <MDTypography
          component="button"
          variant="caption"
          color="success"
          fontWeight="medium"
          onClick={() => handleServiceButtonClick(inPatient)}
        >
          <Icon fontSize="small">medical_services</Icon>
        </MDTypography>
        <MDTypography
          component="button"
          variant="caption"
          color="info"
          fontWeight="medium"
          onClick={() => handleEditInPatient(inPatient)}
        >
          <Icon fontSize="small">edit</Icon>
        </MDTypography>
        <MDTypography
          component="button"
          variant="caption"
          color="error"
          fontWeight="medium"
          onClick={() => handleDeletePatient(inPatient.id)}
        >
          <Icon fontSize="small">delete</Icon>
        </MDTypography>
      </MDBox>
    ),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="customGradient"
                borderRadius="lg"
                coloredShadow="#EA257C"
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <MDTypography variant="h6" color="white">
                  In Patients
                </MDTypography>
                <MDBox display="flex" gap={2}>
                  <MDInput
                    label="Search"
                    className='text-white'
                    value={searchTerm}
                    inputProps={{
                      style: {
                        color: 'white', 
                      },
                    }}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <Link to="/patientsregistration">
                    <MDButton variant="contained" color="white">
                      Add IP
                    </MDButton>
                  </Link>
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Modal
        open={openServiceModal}
        onClose={handleCloseServiceModal}
        aria-labelledby="service-modal-title"
        aria-describedby="service-modal-description"
      >
        <Card sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            p: 4,
          }}>
          <MDTypography variant="h4" mb={2} gutterBottom fontWeight="bold">
            Add Services
          </MDTypography>
            <Select
              multiple
              displayEmpty
              value={selectedServices}
              onChange={(e) => setSelectedServices(e.target.value)}
              fullWidth
              className='p-3'
              renderValue={(selected) =>
                selected.length === 0 ? 'Add Services' : selected.join(', ')
              }
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 224,
                    width: 250,
                  },
                },
              }}
            >
              {availableServices.map((service) => (
                <MenuItem key={service.name} value={service.name}>
                  <Checkbox checked={selectedServices.indexOf(service.name) > -1} />
                  {service.name}
                </MenuItem>
              ))}
            </Select>
          
          <MDBox p={2} display="flex" justifyContent="flex-end" gap={2}>
          <MDButton onClick={()=> handleSaveSelectedServices(editingInPatient.id)} variant="contained" color="success">
              Save
            </MDButton>
            <MDButton onClick={handleCloseServiceModal} variant="contained" color="error">
              Cancel
            </MDButton>
          </MDBox>
        </Card>
      </Modal>
      <Modal
        open={openViewModal}
        onClose={handleCloseViewModal}
        aria-labelledby="view-modal-title"
        aria-describedby="view-modal-description"
      >
        <Card sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          p: 4,
        }}>
          
          <MDTypography variant="h4" mb={2} gutterBottom fontWeight="bold">
          Patient Details
        </MDTypography>
            <MDTypography variant="body2" mb={1}>
              <strong>UHID:</strong> {viewingInPatient?.uhid}
            </MDTypography>
            <MDTypography variant="body2" mb={1}>
              <strong>Name:</strong> {viewingInPatient?.name}
            </MDTypography>
            <MDTypography variant="body2" mb={1}>
              <strong>Gender:</strong> {viewingInPatient?.gender}
            </MDTypography>
            <MDTypography variant="body2" mb={1}>
              <strong>Age:</strong> {viewingInPatient?.age}
            </MDTypography>
            <MDTypography variant="body2" mb={1}>
              <strong>Email:</strong> {viewingInPatient?.email}
            </MDTypography>
            <MDTypography variant="body2" mb={1}>
              <strong>City:</strong> {viewingInPatient?.city}
            </MDTypography>
            <MDTypography variant="body2" mb={1}>
              <strong>State:</strong> {viewingInPatient?.state}
            </MDTypography>
            <MDTypography variant="body2" mb={1}>
              <strong>Country:</strong> {viewingInPatient?.country}
            </MDTypography>
            <MDTypography variant="body2" mb={1}>
              <strong>Admission Date:</strong> {viewingInPatient?.admissionDate}
            </MDTypography>
            <MDTypography variant="body2" mb={1}>
              <strong>Discharge Date:</strong> {viewingInPatient?.dischargedDate}
            </MDTypography>
            <MDTypography variant="body2" mb={1}>
              <strong>Services:</strong> {viewingInPatient?.service}
            </MDTypography>
            <MDTypography variant="body2" mb={1}>
              <strong>Doctors:</strong> {viewingInPatient?.doctor}
            </MDTypography>
            <MDTypography variant="body2" mb={1}>
              <strong>Total Cost:</strong> {viewingInPatient?.fees}
            </MDTypography>
          <MDBox p={2} display="flex" justifyContent="flex-end" gap={2}>
            <MDButton variant="contained"
            color="primary" onClick={handleCloseViewModal}>
              Close
            </MDButton>
            <MDButton variant="contained"
            color="info" onClick={handleEditButtonClickInViewModal}>
              Edit
            </MDButton>
          </MDBox>
        </Card>
      </Modal>
      <ToastContainer position="top-right" />
    </DashboardLayout>
  );
};

export default InPatientsLists;
