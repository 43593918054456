import React, { useState } from "react";
import { Link } from "react-router-dom";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import Modal from "@mui/material/Modal";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const InvoicePage = () => {
  const initialOutPatients = [
    {
      id: 1,
      name: "John Doe",
      invoiceDate: "2024-07-01",
      phoneNumber: "123-456-7890",
      city: "New York",
      email: "john.doe@example.com",
      totalAmount: 100,
      status: "Paid",
      uhid: "UHID123",
      doctorName: "Dr. Smith",
    },
    {
      id: 2,
      name: "Jane Smith",
      invoiceDate: "2024-07-02",
      phoneNumber: "234-567-8901",
      city: "Los Angeles",
      email: "jane.smith@example.com",
      totalAmount: 150,
      status: "Pending",
      uhid: "UHID124",
      doctorName: "Dr. Johnson",
    },
    {
      id: 3,
      name: "Alice Johnson",
      invoiceDate: "2024-07-03",
      phoneNumber: "345-678-9012",
      city: "Chicago",
      email: "alice.johnson@example.com",
      totalAmount: 200,
      status: "Paid",
      uhid: "UHID125",
      doctorName: "Dr. Lee",
    },
    {
      id: 4,
      name: "Bob Brown",
      invoiceDate: "2024-07-04",
      phoneNumber: "456-789-0123",
      city: "Houston",
      email: "bob.brown@example.com",
      totalAmount: 250,
      status: "Pending",
      uhid: "UHID126",
      doctorName: "Dr. Davis",
    },
    {
      id: 5,
      name: "Carol White",
      invoiceDate: "2024-07-05",
      phoneNumber: "567-890-1234",
      city: "Phoenix",
      email: "carol.white@example.com",
      totalAmount: 300,
      status: "Paid",
      uhid: "UHID127",
      doctorName: "Dr. Garcia",
    },
    {
      id: 6,
      name: "David Wilson",
      invoiceDate: "2024-07-06",
      phoneNumber: "678-901-2345",
      city: "Philadelphia",
      email: "david.wilson@example.com",
      totalAmount: 350,
      status: "Pending",
      uhid: "UHID128",
      doctorName: "Dr. Martinez",
    },
    {
      id: 7,
      name: "Emily Clark",
      invoiceDate: "2024-07-07",
      phoneNumber: "789-012-3456",
      city: "San Antonio",
      email: "emily.clark@example.com",
      totalAmount: 400,
      status: "Paid",
      uhid: "UHID129",
      doctorName: "Dr. Anderson",
    },
    {
      id: 8,
      name: "Frank Hall",
      invoiceDate: "2024-07-08",
      phoneNumber: "890-123-4567",
      city: "San Diego",
      email: "frank.hall@example.com",
      totalAmount: 450,
      status: "Pending",
      uhid: "UHID130",
      doctorName: "Dr. Thomas",
    },
    {
      id: 9,
      name: "Grace Lewis",
      invoiceDate: "2024-07-09",
      phoneNumber: "901-234-5678",
      city: "Dallas",
      email: "grace.lewis@example.com",
      totalAmount: 500,
      status: "Paid",
      uhid: "UHID131",
      doctorName: "Dr. Hernandez",
    },
    {
      id: 10,
      name: "Hannah Young",
      invoiceDate: "2024-07-10",
      phoneNumber: "012-345-6789",
      city: "San Jose",
      email: "hannah.young@example.com",
      totalAmount: 550,
      status: "Pending",
      uhid: "UHID132",
      doctorName: "Dr. Walker",
    },
  ];

  const [outPatients, setOutPatients] = useState(initialOutPatients);
  const [editingOutPatient, setEditingOutPatient] = useState(null);
  const [viewingOutPatient, setViewingOutPatient] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleEditOutPatient = (outPatient) => {
    setEditingOutPatient(outPatient);
    setOpenEditModal(true);
  };

  const handleSaveEditedOutPatient = () => {
    setOutPatients((prevOutPatients) =>
      prevOutPatients.map((patient) =>
        patient.id === editingOutPatient.id ? editingOutPatient : patient
      )
    );
    setEditingOutPatient(null);
    setOpenEditModal(false);
  };

  const handleCloseEditModal = () => {
    setEditingOutPatient(null);
    setOpenEditModal(false);
  };

  const handleViewOutPatient = (outPatient) => {
    setViewingOutPatient(outPatient);
    setOpenViewModal(true);
  };

  const handleCloseViewModal = () => {
    setViewingOutPatient(null);
    setOpenViewModal(false);
  };

  const handleEditButtonClickInViewModal = () => {
    if (viewingOutPatient) {
      handleEditOutPatient(viewingOutPatient);
      setOpenViewModal(false);
    }
  };

  const filteredOutPatients = outPatients.filter((outPatient) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      outPatient.uhid.toLowerCase().includes(searchLower) ||
      outPatient.name.toLowerCase().includes(searchLower) ||
      outPatient.phoneNumber.toLowerCase().includes(searchLower) ||
      outPatient.city.toLowerCase().includes(searchLower) ||
      outPatient.invoiceDate.toLowerCase().includes(searchLower)
    );
  });

  const columns = [
    { Header: "#", accessor: "serialNo", width: "5%", align: "center" },
    { Header: "UHID", accessor: "uhid", width: "15%", align: "center" },
    { Header: "Name", accessor: "name", width: "20%", align: "left" },
    { Header: "Invoice Date", accessor: "invoiceDate", width: "15%", align: "center" },
    { Header: "Phone Number", accessor: "phoneNumber", width: "15%", align: "left" },
    { Header: "City", accessor: "city", width: "15%", align: "center" },
    { Header: "Email", accessor: "email", width: "15%", align: "center" },
    { Header: "Total Amount", accessor: "totalAmount", width: "15%", align: "center" },
    { Header: "Status", accessor: "status", width: "10%", align: "center" },
    { Header: "Action", accessor: "action", align: "center" },
  ];

  const rows = filteredOutPatients.map((outPatient, index) => ({
    serialNo: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {index + 1}
      </MDTypography>
    ),
    uhid: (
      <MDTypography
        component="button"
        variant="caption"
        color="text"
        fontWeight="medium"
        onClick={() => handleViewOutPatient(outPatient)}
      >
        {outPatient.uhid}
      </MDTypography>
    ),
    name: (
      <MDTypography
        component="button"
        variant="caption"
        color="text"
        fontWeight="medium"
        onClick={() => handleViewOutPatient(outPatient)}
      >
        {outPatient.name}
      </MDTypography>
    ),
    invoiceDate: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {outPatient.invoiceDate}
      </MDTypography>
    ),
    phoneNumber: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {outPatient.phoneNumber}
      </MDTypography>
    ),
    city: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {outPatient.city}
      </MDTypography>
    ),
    email: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {outPatient.email}
      </MDTypography>
    ),
    totalAmount: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {outPatient.totalAmount}
      </MDTypography>
    ),
    status: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {outPatient.status}
      </MDTypography>
    ),
    action: (
      <MDBox display="flex" justifyContent="center">
        <EditIcon
          color="primary"
          onClick={() => handleEditOutPatient(outPatient)}
          sx={{ cursor: "pointer", mx: 1 }}
        />
        <DeleteIcon
          color="error"
          onClick={() => setOutPatients(outPatients.filter((p) => p.id !== outPatient.id))}
          sx={{ cursor: "pointer" }}
        />
      </MDBox>
    ),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Card>
          <MDBox
            mx={3}
            mt={-3}
            py={2}
            px={2}
            variant="gradient"
            bgColor="customGradient"
            borderRadius="lg"
            coloredShadow="#EA257C"
            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
          >
            <MDTypography variant="h6" color="white">
              Invoice
            </MDTypography>
            <MDBox display="flex" gap={2}>
              <MDInput
                label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Link to="/createinvoice">
                <MDButton variant="contained" color="white">
                  + Create Invoice
                </MDButton>
              </Link>
            </MDBox>
          </MDBox>
          <MDBox pt={3}>
            <DataTable
              table={{ columns, rows }}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
            />
          </MDBox>
        </Card>
      </MDBox>

      <Modal open={openEditModal} onClose={handleCloseEditModal}>
        <MDBox
          p={3}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            backgroundColor: "white",
            boxShadow: 24,
            borderRadius: "8px",
          }}
        >
          <MDTypography variant="h5" gutterBottom fontWeight="bold">
            Edit Invoice
          </MDTypography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MDInput
                label="UHID"
                value={editingOutPatient?.uhid || ""}
                onChange={(e) =>
                  setEditingOutPatient({ ...editingOutPatient, uhid: e.target.value })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MDInput
                label="Name"
                value={editingOutPatient?.name || ""}
                onChange={(e) =>
                  setEditingOutPatient({ ...editingOutPatient, name: e.target.value })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MDInput
                label="Invoice Date"
                value={editingOutPatient?.invoiceDate || ""}
                onChange={(e) =>
                  setEditingOutPatient({ ...editingOutPatient, invoiceDate: e.target.value })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MDInput
                label="Phone Number"
                value={editingOutPatient?.phoneNumber || ""}
                onChange={(e) =>
                  setEditingOutPatient({ ...editingOutPatient, phoneNumber: e.target.value })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MDInput
                label="City"
                value={editingOutPatient?.city || ""}
                onChange={(e) =>
                  setEditingOutPatient({ ...editingOutPatient, city: e.target.value })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MDInput
                label="Email"
                value={editingOutPatient?.email || ""}
                onChange={(e) =>
                  setEditingOutPatient({ ...editingOutPatient, email: e.target.value })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MDInput
                label="Total Amount"
                value={editingOutPatient?.totalAmount || ""}
                onChange={(e) =>
                  setEditingOutPatient({ ...editingOutPatient, totalAmount: e.target.value })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MDInput
                label="Status"
                value={editingOutPatient?.status || ""}
                onChange={(e) =>
                  setEditingOutPatient({ ...editingOutPatient, status: e.target.value })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <MDBox display="flex" justifyContent="flex-end" mt={2}>
                <MDButton color="primary" onClick={handleSaveEditedOutPatient}>
                  Save
                </MDButton>
                <MDButton color="error" onClick={handleCloseEditModal} sx={{ ml: 2 }}>
                  Cancel
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </Modal>

      <Modal open={openViewModal} onClose={handleCloseViewModal}>
        <MDBox
          p={3}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            backgroundColor: "white",
            boxShadow: 24,
            borderRadius: "8px",
          }}
        >
          <MDTypography variant="h5" gutterBottom fontWeight="bold">
            View Invoice
          </MDTypography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MDTypography variant="body1" gutterBottom fontWeight="bold">
                UHID
              </MDTypography>
              <MDTypography variant="body2">{viewingOutPatient?.uhid}</MDTypography>
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="body1" gutterBottom fontWeight="bold">
                Name
              </MDTypography>
              <MDTypography variant="body2">{viewingOutPatient?.name}</MDTypography>
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="body1" gutterBottom fontWeight="bold">
                Invoice Date
              </MDTypography>
              <MDTypography variant="body2">{viewingOutPatient?.invoiceDate}</MDTypography>
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="body1" gutterBottom fontWeight="bold">
                Phone Number
              </MDTypography>
              <MDTypography variant="body2">{viewingOutPatient?.phoneNumber}</MDTypography>
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="body1" gutterBottom fontWeight="bold">
                City
              </MDTypography>
              <MDTypography variant="body2">{viewingOutPatient?.city}</MDTypography>
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="body1" gutterBottom fontWeight="bold">
                Email
              </MDTypography>
              <MDTypography variant="body2">{viewingOutPatient?.email}</MDTypography>
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="body1" gutterBottom fontWeight="bold">
                Total Amount
              </MDTypography>
              <MDTypography variant="body2">{viewingOutPatient?.totalAmount}</MDTypography>
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="body1" gutterBottom fontWeight="bold">
                Status
              </MDTypography>
              <MDTypography variant="body2">{viewingOutPatient?.status}</MDTypography>
            </Grid>
            <Grid item xs={12}>
              <MDBox display="flex" justifyContent="flex-end" mt={2}>
                <MDButton color="primary" onClick={handleEditButtonClickInViewModal}>
                  <EditIcon />
                  Edit
                </MDButton>
                <MDButton color="error" onClick={handleCloseViewModal} sx={{ ml: 2 }}>
                  <MDTypography variant="button" color="white" fontWeight="medium">
                    Close
                  </MDTypography>
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </Modal>
    </DashboardLayout>
  );
};

export default InvoicePage;
