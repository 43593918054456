import React, { useState, useEffect } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

const Services = () => {
  const [services, setServices] = useState([]);
  const [nextId, setNextId] = useState(14); // Initial nextId, adjust based on your current data
  const [editingServiceId, setEditingServiceId] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const [serviceName, setServiceName] = useState("");
  const [amount, setAmount] = useState("");

  const [editedServiceName, setEditedServiceName] = useState("");
  const [editedServiceAmount, setEditedServiceAmount] = useState("");

  const sessionId = useSelector((state) => state.session.sessionId);

  useEffect(() => {
    const fetchServices = async () => {
      const apiUrl = "https://veerahospital.com/billing-software/service/v4_1/rest.php";
      const method = "get_entry_list";
      const input_type = "JSON";
      const response_type = "JSON";
      const rest_data = JSON.stringify({
        session: sessionId,
        module_name: "SVC_Services",
        select_fields: ["service_name_c", "price_c"],
      });

      const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

      try {
        const response = await axios.get(fullUrl);
        console.log("API Response:", response.data);

        const getServiceData = () => {
          if (response.data && response.data.entry_list) {
            return response.data.entry_list.map((entry) => {
              const data = entry.name_value_list;
              return {
                id: entry.id,
                name: data.service_name_c.value,
                amount: data.price_c.value,
              };
            });
          }
          return [];
        };

        const serviceData = getServiceData();
        setServices(serviceData);

        // Update nextId based on the highest current ID
        const maxId = serviceData.reduce((max, service) => Math.max(max, service.id), 0);
        setNextId(maxId + 1);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (sessionId) {
      fetchServices();
    }
  }, [sessionId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (serviceName.trim() === "" || amount.trim() === "") return;

    const newService = {
      id: nextId,
      name: serviceName,
      amount: parseFloat(amount),
    };

    setServices([...services, newService]);
    setNextId(nextId + 1);
    setServiceName("");
    setAmount("");

    const apiUrl = "https://veerahospital.com/billing-software/service/v4_1/rest.php";
    const method = "set_entry";
    const input_type = "JSON";
    const response_type = "JSON";
    const rest_data = JSON.stringify({
      session: sessionId,
      module_name: "SVC_Services",
      name_value_list: [
        { name: "id", value: newService.id },
        { name: "service_name_c", value: newService.name },
        { name: "price_c", value: newService.amount },
      ],
    });
    const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

    try {
      const response = await axios.post(fullUrl);

      if (response.status === 200 && response.data.id) {
        console.log("Service added successfully:", response.data);
        toast.success("Service added successfully!");
      } else {
        console.log("Service addition failed:", response.data);
        toast.error("Service addition failed.");
      }
    } catch (error) {
      if (error.response) {
        console.error("An error occurred during service addition:", error.response.data);
        toast.error("An error occurred during service addition.");
      } else if (error.request) {
        console.error("No response received:", error.request);
        toast.error("No response received from server.");
      } else {
        console.error("Error setting up request:", error.message);
        toast.error("Error setting up request.");
      }
    }
  };

  const handleServiceNameChange = (e) => {
    setServiceName(e.target.value);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleEditService = (serviceId) => {
    console.log("Edit service called for ID:", serviceId); // Debugging line
    const serviceToEdit = services.find((service) => service.id === serviceId);
    if (serviceToEdit) {
      setEditingServiceId(serviceId);
      setEditedServiceName(serviceToEdit.name);
      setEditedServiceAmount(serviceToEdit.amount);
      setOpenModal(true);
    }
  };

  const handleSaveEditedService = async (event) => {
    event.preventDefault();
    if (editedServiceName.trim() === "" || editedServiceAmount.toString().trim() === "") return;

    const updatedServices = services.map((service) =>
      service.id === editingServiceId
        ? {
            ...service,
            name: editedServiceName,
            amount: parseFloat(editedServiceAmount),
          }
        : service
    );

    setServices(updatedServices); // Update state before making the API call

    const apiUrl = "https://veerahospital.com/billing-software/service/v4_1/rest.php";
    const method = "set_entry";
    const input_type = "JSON";
    const response_type = "JSON";
    const rest_data = JSON.stringify({
      session: sessionId,
      module_name: "SVC_Services",
      name_value_list: [
        { name: "id", value: editingServiceId },
        { name: "service_name_c", value: editedServiceName },
        { name: "price_c", value: editedServiceAmount },
      ],
    });
    const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

    try {
      const response = await axios.post(fullUrl);

      if (response.status === 200 && response.data.id) {
        console.log("Service updated successfully:", response.data);
        toast.success("Service updated successfully!");
        handleCloseModal();
      } else {
        console.log("Service update failed:", response.data);
        toast.error("Service update failed.");
      }
    } catch (error) {
      if (error.response) {
        console.error("An error occurred during service update:", error.response.data);
        toast.error("An error occurred during service update.");
      } else if (error.request) {
        console.error("No response received:", error.request);
        toast.error("No response received from server.");
      } else {
        console.error("Error setting up request:", error.message);
        toast.error("Error setting up request.");
      }
    }
  };

  const handleCloseModal = () => {
    setEditingServiceId(null);
    setEditedServiceName("");
    setEditedServiceAmount("");
    setOpenModal(false);
  };

  const handleDeleteService = async (serviceId) => {
    console.log("Delete service called for ID:", serviceId); // Debugging line
    const apiUrl = "https://veerahospital.com/billing-software/service/v4_1/rest.php";
    const method = "set_entry";
    const input_type = "JSON";
    const response_type = "JSON";

    const rest_data = JSON.stringify({
      session: sessionId,
      module_name: "SVC_Services",
      name_value_list: {
        id: serviceId,
        deleted: { name: "deleted", value: "1" },
      },
    });

    const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

    try {
      const response = await axios.post(fullUrl);

      if (response.status === 200 && response.data.id) {
        console.log("Service deleted successfully:", response.data);
        toast.success("Service deleted successfully!");
        setServices(services.filter((service) => service.id !== serviceId));
      } else {
        console.log("Service delete failed:", response.data);
        toast.error("Service delete failed.");
      }
    } catch (error) {
      if (error.response) {
        console.error("An error occurred during service delete:", error.response.data);
        toast.error("An error occurred during service delete.");
      } else if (error.request) {
        console.error("No response received:", error.request);
        toast.error("No response received from server.");
      } else {
        console.error("Error setting up request:", error.message);
        toast.error("Error setting up request.");
      }
    }
  };
  const columns = [
    {
      Header: "S.No",
      accessor: "serialNumber",
    },
    { Header: "Service Name", accessor: "name" },
    { Header: "Amount", accessor: "amount" },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }) => (
        <div>
          <IconButton onClick={() => handleEditService(row.original.id)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDeleteService(row.original.id)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];
  const rows = services
    .filter(
      (service) =>
        service.name &&
        service.name.trim() !== "" &&
        service.amount &&
        service.amount.toString().trim() !== ""
    )
    .map((service, index) => ({
      serialNumber: index + 1,
      id: service.id,
      name: service.name,
      amount: service.amount,
    }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          {/* Add Services Section */}
          <Grid item xs={12} md={6}>
            <Card>
              <MDBox p={3}>
                <MDTypography variant="h6" color="textPrimary">
                  Add Services
                </MDTypography>
                <form onSubmit={handleSubmit}>
                  <MDBox mb={2}>
                    <MDInput
                      label="Service Name"
                      value={serviceName}
                      onChange={handleServiceNameChange}
                      fullWidth
                      required
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="number"
                      label="Amount"
                      value={amount}
                      onChange={handleAmountChange}
                      fullWidth
                      required
                    />
                  </MDBox>
                  <MDBox display="flex" justifyContent="center">
                    <MDButton type="submit" variant="contained" color="primary">
                      Add Service
                    </MDButton>
                  </MDBox>
                </form>
              </MDBox>
            </Card>
          </Grid>

          {/* Available Services Section */}
          <Grid item xs={12} md={6}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="customGradient"
                borderRadius="lg"
                coloredShadow="#EA257C"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <MDTypography variant="h6" color="white">
                  Available Services
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>

        {/* Edit Service Modal */}
        <Modal open={openModal} onClose={handleCloseModal}>
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            p={3}
            sx={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          >
            <Card sx={{ width: "40%", p: 3 }}>
              <MDTypography variant="h6">Edit Service</MDTypography>
              <form onSubmit={handleSaveEditedService}>
                <MDBox mb={2}>
                  <MDInput
                    label="Service Name"
                    value={editedServiceName}
                    onChange={(e) => setEditedServiceName(e.target.value)}
                    fullWidth
                    required
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="number"
                    label="Amount"
                    value={editedServiceAmount}
                    onChange={(e) => setEditedServiceAmount(e.target.value)}
                    fullWidth
                    required
                  />
                </MDBox>
                <MDBox display="flex" justifyContent="space-between">
                  <MDButton type="submit" variant="contained" color="primary">
                    Save
                  </MDButton>
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="secondary"
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </MDButton>
                </MDBox>
              </form>
            </Card>
          </MDBox>
        </Modal>
      </MDBox>
      <ToastContainer />
    </DashboardLayout>
  );
};

export default Services;
