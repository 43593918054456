import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import data from 'data.json';
import { BASE_API_URL } from "api/Api";
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

const EditOutPatient = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [outPatient, setOutPatient] = useState(null);
    const [availableDoctors, setAvailableDoctors] = useState([]);
    const [availableServices, setAvailableServices] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState([]); 
    const [selectedServices, setSelectedServices] = useState([]);
    const [outPatients, setOutPatients] = useState([]); 
    const sessionId = useSelector((state) => state.session.sessionId);

    useEffect(() => {

        const fetchOutPatients = async () => {
            const apiUrl = BASE_API_URL;
            const method = "get_entry_list";
            const input_type = "JSON";
            const response_type = "JSON";
            const rest_data = JSON.stringify({
                session: sessionId,
                module_name: "Contacts",
            });

            const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

            try {
                const response = await axios.get(fullUrl);
                console.log("API Response:", response.data);

                const getPatientsData = () => {
                    if (response.data && response.data.entry_list) {
                        return response.data.entry_list.map(entry => {
                            const data = entry.name_value_list;
                            return {
                                id: entry.id,
                                uhid: data.uhid_c.value,
                                name: data.pateintname_c.value,
                                gender: data.patient_gender_c.value,
                                patientType: data.patienttype_c.value,
                                age: data.patient_age_c.value,
                                email1: data.email.value,
                                phone: data.phone_mobile.value,
                                city: data.city_c.value,
                                state: data.state_c.value,
                                country: data.patient_country_name_c.value,
                                admissionDate: data.admissiondate_c.value,
                                dischargedDate: data.dischargeddate_c.value,
                                services: data.servicesnames_c.value.split(',')
                                .map(item => item.trim())
                                .filter(item => item !== ''),
                                serviceCost: data.servicecost_c.value,
                                fees: data.totalcost_c.value,
                                doctors: data.doc_c.value.split(',')
                                .map(item => item.trim())
                                .filter(item => item !== ''),
                            };
                        });
                    }
                    return [];
                };

                const outPatientsData = getPatientsData();
                setOutPatients(outPatientsData); 
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Error fetching data");
            }
        };

        if (sessionId) {
            fetchOutPatients();
        }
    }, []);

    useEffect(() => {
        const { doctors, services } = data;
        setAvailableDoctors(doctors || []);
        setAvailableServices(services || []);

        const patient = outPatients.find((patient) => patient.id === (id));
        if (patient) {
            setOutPatient(patient);
            setSelectedDoctor(patient.doctors || []); 
            setSelectedServices(patient.services || []);
        }
    }, [id, outPatients]);

    const handleDoctorChange = (event) => {
        const { value } = event.target;
        setSelectedDoctor(typeof value === 'string' ? value.split(',') : value);
      };
    const handleServiceChange = (event) => {
        const { value } = event.target;
        setSelectedServices(typeof value === 'string' ? value.split(',') : value);
      };

    const handleSaveEditedOutPatient = async (event) => {
        event.preventDefault();

        // Construct the updated patient data object
        const updatedOutPatient = {
            id: outPatient.id,
            assignedDoctor: selectedDoctor,
            services: selectedServices,
            uhid: outPatient.uhid,
            name: outPatient.name,
            gender: outPatient.gender,
            age: outPatient.age,
            phone: outPatient.phone,
            city: outPatient.city,
            state: outPatient.country,
            email: outPatient.email,
            fees: outPatient.fees,
        };

        // API call to save the updated outpatient data
        const apiUrl = `${BASE_API_URL}`;
        const method = "set_entry";
        const input_type = "JSON";
        const response_type = "JSON";

        const rest_data = JSON.stringify({
            session: sessionId,
            module_name: "Contacts",
            name_value_list: {
                id: updatedOutPatient.id,
                uhid_c: updatedOutPatient.uhid,
                pateintname_c: updatedOutPatient.name,
                patient_gender_c: updatedOutPatient.gender,
                patient_age_c: updatedOutPatient.age,
                phone_mobile: updatedOutPatient.phone,
                city_c: updatedOutPatient.city,
                state_c: updatedOutPatient.country,
                servicesnames_c: updatedOutPatient.services.join(', '),
                totalcost_c: updatedOutPatient.fees,
                email1: updatedOutPatient.email,
                doc_c: updatedOutPatient.assignedDoctor.join(', '),
            },
        });

        const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

        try {
            const response = await axios.post(fullUrl);

            if (response.status === 200 && response.data.id) {
                console.log("OutPatient updated successfully:", response.data);
                setOutPatients((prevOutPatients) =>
                    prevOutPatients.map((p) =>
                        p.id === updatedOutPatient.id ? updatedOutPatient : p
                    )
                ); 
                toast.success("Patient Updated Succesfully")
                navigate('/outpatients');
            } else {
                console.error("OutPatient update failed:", response.data);
                toast.error("Patient Update Failed")
            }
        } catch (error) {
            if (error.response) {
                console.error("Error during OutPatient update:", error.response.data);
                console.error("Status code:", error.response.status);
            } else if (error.request) {
                console.error("No response received:", error.request);
            } else {
                console.error("Error setting up request:", error.message);
            }
        }
    };

    if (!outPatient) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <MDBox py={4} px={3}>
                    <MDTypography variant="h5" fontWeight="bold">
                        No Out Patients available at the moment
                    </MDTypography>
                </MDBox>
            </DashboardLayout>
        );
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox py={4} mx={5} px={3}>
                <MDTypography variant="h5" mb={2} gutterBottom fontWeight="bold" align="center">
                    Edit Out Patients
                </MDTypography>
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={6}>
                        <MDInput
                            label="UHID"
                            value={outPatient.uhid || ''}
                            onChange={(e) => setOutPatient({ ...outPatient, uhid: e.target.value })}
                            fullWidth
                        />
                        <MDInput
                            label="Name"
                            value={outPatient.name || ''}
                            onChange={(e) => setOutPatient({ ...outPatient, name: e.target.value })}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                        <MDInput
                            label="Age"
                            type="number"
                            value={outPatient.age || ''}
                            onChange={(e) => setOutPatient({ ...outPatient, age: e.target.value })}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                        <MDInput
                            label="Gender"
                            value={outPatient.gender || ''}
                            onChange={(e) => setOutPatient({ ...outPatient, gender: e.target.value })}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                        <MDInput
                            label="Phone Number"
                            value={outPatient.phone || ''}
                            onChange={(e) => setOutPatient({ ...outPatient, phone: e.target.value })}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                        <MDInput
                            label="City"
                            value={outPatient.city || ''}
                            onChange={(e) => setOutPatient({ ...outPatient, city: e.target.value })}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} mt={-1}>
                        <label htmlFor="doctor-select" className="text-sm">Doctor</label>
                        <Select
                            id="doctor-select"
                            multiple
                            value={selectedDoctor}
                            onChange={handleDoctorChange}
                            // onChange={(e) => setSelectedDoctor(e.target.value)}
                            displayEmpty
                            fullWidth
                            className="p-3"
                            renderValue={(selected) => (selected.length === 0 ? 'Add Doctor' : selected.join(', '))}
                            sx={{ mt: 1 }}
                            MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
                        >
                            {availableDoctors.map((doctor) => (
                                <MenuItem key={doctor} value={doctor}>
                                    <Checkbox checked={selectedDoctor.indexOf(doctor) > -1} />
                                    {doctor}
                                </MenuItem>
                            ))}
                        </Select>
                        <label htmlFor="service-select" className="text-sm" style={{ marginTop: 20 }}>Services</label>
                        <Select
                            id="service-select"
                            multiple
                            value={selectedServices}
                            onChange={handleServiceChange}
                            // onChange={(e) => setSelectedServices(e.target.value)}
                            displayEmpty
                            fullWidth
                            className="p-3"
                            renderValue={(selected) => (selected.length === 0 ? 'Add Services' : selected.join(', '))}
                            sx={{ mt: 1 }}
                        >
                            {availableServices.map((service) => (
                                <MenuItem key={service.name} value={service.name}>
                                    <Checkbox checked={selectedServices.indexOf(service.name) > -1} />
                                    {service.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <MDInput
                            label="Fees"
                            type="number"
                            value={outPatient.fees || ''}
                            onChange={(e) => setOutPatient({ ...outPatient, fees: e.target.value })}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                        <MDInput
                            label="Email"
                            value={outPatient.email || ''}
                            onChange={(e) => setOutPatient({ ...outPatient, email: e.target.value })}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                        <MDInput
                            label="State"
                            value={outPatient.country || ''}
                            onChange={(e) => setOutPatient({ ...outPatient, country: e.target.value })}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                    </Grid>
                </Grid>
                <MDBox mt={5} mb={2} display="flex" gap="40px" justifyContent="center">
                    <MDButton
                        variant="contained"
                        color="error"
                        onClick={() => navigate('/outpatients')}
                    >
                        Cancel
                    </MDButton>
                    <MDButton variant="contained" color="success" onClick={handleSaveEditedOutPatient}>
                        Save
                    </MDButton>
                </MDBox>
            </MDBox>
            <ToastContainer position="top-right" />
        </DashboardLayout>
    );
};

export default EditOutPatient;
