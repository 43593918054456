// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";

import Billing from "layouts/billing";

import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Invoice from "layouts/invoice";
import Createinvoice from "layouts/createinvoice";
import Dischargesummary from "layouts/dischargesummary/summary";

// @mui icons
import Icon from "@mui/material/Icon";
import InPatientsLists from "layouts/Patients/InPatientLists";
import OutPatientsLists from "layouts/Patients/OutPatientLists";
import PatientsRegistration from "layouts/Patients/PatientsRegistration";
import Services from "layouts/Services/Services";

import Doctors from "layouts/doctors/doctors";
import Addsummary from "layouts/dischargesummary/addsummary";
import Editsummary from "layouts/dischargesummary/editsummary";
import EditOutPatient from "layouts/Patients/OutPatientsEditView";
import EditInPatient from "layouts/Patients/InPatientsEditView";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Doctors",
    key: "doctors",
    icon: <Icon fontSize="small">health_and_safety_icon</Icon>,
    route: "/doctors",
    component: <Doctors />,
  },

  {
    type: "collapse",
    name: "Patients Registration",
    key: "patientsregistration",
    icon: <Icon fontSize="small">person_add</Icon>,
    route: "/patientsregistration",
    component: <PatientsRegistration />,
  },
  {
    type: "collapse",
    name: "Out Patients",
    key: "outpatients",
    icon: <Icon fontSize="small">local_hospital</Icon>,
    route: "/outpatients",
    component: <OutPatientsLists />,
  },
  {
    type: "collapse",
    name: "In Patients",
    key: "inpatients",
    icon: <Icon fontSize="small">hotel</Icon>,
    route: "/inpatients",
    component: <InPatientsLists />,
  },
  {
    type: "collapse",
    name: "Dischargesummary",
    key: "dischargesummary",
    icon: <Icon fontSize="small">description</Icon>,
    route: "/dischargesummary",
    component: <Dischargesummary />,
  },
  {
    type: "collapse",
    name: "Invoice",
    key: "invoice",
    icon: <Icon fontSize="small">monetization_on</Icon>,
    route: "/invoice",
    component: <Invoice />,
  },

  {
    type: "collapse",
    name: "Services",
    key: "services",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/services",
    component: <Services />,
  },
  {
    type: "collapse",
    name: "Hospitality",
    key: "hospitality",
    icon: <Icon fontSize="small">local_cafe</Icon>,
  },
  {
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },
  {
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/login",
    component: <SignIn />,
  },
  {
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  {
    route: "/editoutpatient/:id",
    component: <EditOutPatient />,
  },
  {
    route: "/editinpatient/:id",
    component: <EditInPatient />,
  },
  {
    //type: "collapse",
    name: "createinvoice",
    key: "createinvoice",
    route: "/createinvoice",
    component: <Createinvoice />,
  },
  {
    //name: "addsummary",
    key: "addsummary",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/dischargesummary/addsummary",
    component: <Addsummary />,
  },
  {
    //name: "editsummary",
    key: "editsummary",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/dischargesummary/editsummary",
    component: <Editsummary />,
  },
];

export default routes;
