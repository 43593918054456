import React, { useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import logoImage from "assets/images/HMS.webp";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import jsPDF from "jspdf"; 
import "jspdf-autotable"; 
import { useNavigate } from 'react-router-dom';



function Invoice() {
    const [toDetails, setToDetails] = useState({
      name: "",
      age: "",
      id: "",
      dateOfSurgery: "",
      dateOfDischarge: "",
      dateOfAdmission: "",
      phone: "",
    });
  
  const invoiceDatas = [
    {
        id: 1,
        name: 'Vicky',
        dateOfAdmission: '2024-07-01',
        dateOfSurgery: '2024-07-02',
        dateOfDischarge: '2024-07-03',
        age: 30,
        phoneNumber: '9790675433',
      },
      {
        id: 2,
        name: 'Jane Smith',
        dateOfAdmission: '2024-07-02',
        dateOfSurgery: '2024-07-03',
        dateOfDischarge: '2024-07-04',
        age: 45,
        phoneNumber: '9245672902',
      },
      {
        id: 3,
        name: 'Praveen',
        dateOfAdmission: '2024-07-03',
        dateOfSurgery: '2024-07-04',
        dateOfDischarge: '2024-07-05',
        age: 50,
        phoneNumber: '9368291127',
      },
      {
        id: 4,
        name: 'Karthik',
        dateOfAdmission: '2024-07-04',
        dateOfSurgery: '2024-07-05',
        dateOfDischarge: '2024-07-06',
        age: 40,
        phoneNumber: '9790618699',
      },
      {
        id: 5,
        name: 'Carol White',
        dateOfAdmission: '2024-07-05',
        dateOfSurgery: '2024-07-06',
        dateOfDischarge: '2024-07-07',
        age: 35,
        phoneNumber: '8722964439',
      },
      {
        id: 6,
        name: 'Karan',
        dateOfAdmission: '2024-07-06',
        dateOfSurgery: '2024-07-07',
        dateOfDischarge: '2024-07-08',
        age: 55,
        phoneNumber: '9883445623',
      },
      {
        id: 7,
        name: 'Emily Clark',
        dateOfAdmission: '2024-07-07',
        dateOfSurgery: '2024-07-08',
        dateOfDischarge: '2024-07-09',
        age: 60,
        phoneNumber: '9885334210',
      },
      {
        id: 8,
        name: 'Ragu',
        dateOfAdmission: '2024-07-08',
        dateOfSurgery: '2024-07-09',
        dateOfDischarge: '2024-07-10',
        age: 32,
        phoneNumber: '8965537289',
      },
      {
        id: 9,
        name: 'Grace Lewis',
        dateOfAdmission: '2024-07-09',
        dateOfSurgery: '2024-07-10',
        dateOfDischarge: '2024-07-11',
        age: 28,
        phoneNumber: '9090388213',
      },
      {
        id: 10,
        name: 'Aravind',
        dateOfAdmission: '2024-07-10',
        dateOfSurgery: '2024-07-11',
        dateOfDischarge: '2024-07-12',
        age: 48,
        phoneNumber: '9663729192',
      },
  ];
  
  
  const [invoiceData, setInvoiceData] = useState({
    number: "",
    date: "",
  });
  
  
  const handlePhoneChange = (event) => {
    const { value } = event.target;
    setToDetails((prevDetails) => ({ ...prevDetails, phone: value }));

    const matchedData = invoiceDatas.find(item => item.phoneNumber === value);
    if (matchedData) {
      setToDetails((prevDetails) => ({
        ...prevDetails,
        name: matchedData.name,
        age: matchedData.age,
        phone: matchedData.phoneNumber,
        id: matchedData.id,
        dateOfAdmission: matchedData.dateOfAdmission,
        dateOfSurgery: matchedData.dateOfSurgery,
        dateOfDischarge: matchedData.dateOfDischarge,
      }));
    } else {
      setToDetails((prevDetails) => ({
        ...prevDetails,
        name: "",
        age: "",
        id: "",
        dateOfAdmission: "",
        dateOfSurgery: "",
        dateOfDischarge: "",
      }));
      invoiceDatas.push({
        id: value, 
        name: '',
        age: '',
        phoneNumber: value,
        dateOfAdmission: '',
        dateOfSurgery: '',
        dateOfDischarge: '',
      });
    }
  };

  const handleIdChange = (event) => {
    const value = event.target.value.trim(); 
    console.log("Searching for ID:", value); 

    setToDetails(prevDetails => ({ ...prevDetails, id: value }));

   
    const matchedData = invoiceDatas.find(item => item.id.toString() === value);

    if (matchedData) {
        setToDetails(prevDetails => ({
            ...prevDetails,
            name: matchedData.name,
            age: matchedData.age,
            phone: matchedData.phoneNumber,
            id: matchedData.id,
            dateOfAdmission: matchedData.dateOfAdmission,
            dateOfSurgery: matchedData.dateOfSurgery,
            dateOfDischarge: matchedData.dateOfDischarge,
        }));
    } else {
       
        setToDetails(prevDetails => ({
            ...prevDetails,
            name: '',
            age: '',
            id: value,  
            phone: "",
            dateOfAdmission: '',
            dateOfSurgery: '',
            dateOfDischarge: '',
        }));
    }
};

  const invoiceRef = useRef(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setToDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const [items, setItems] = useState([
    { age: "", hospitality: "", amount: "", totalAmount: "" },
  ]);
  const [savedItems, setSavedItems] = useState([]);
  const [showAddItemForm, setShowAddItemForm] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate('/dischargesummary');
  };

  const handleSave = () => {
    navigate('/dischargesummary');
  };
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={14}>
            <Card>
              <Box p={4} ref={invoiceRef}>
              <Box textAlign="center" mb={3}>
                  <Typography variant="h4" fontWeight="bold" mb={2}>
                    Discharge Summary
                  </Typography>
                </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <Box>
                        <Typography variant="body1" component="div">
                        <strong>VeeraHospital</strong>
                        <br />
                        01, Silambani Sannathi Street, inside Arch Devakottai 630 302
                        <br />
                        <strong>Email:</strong> veerahospital@gmail.com
                        <br />
                        <strong>Phone-No:</strong> 983455675345
                        </Typography>
                    </Box>
                    <Box>
                        <img src={logoImage} alt="Invoice Logo" style={{ width: '150px' }} />
                    </Box>
                    </Box>
                <Box display="flex" justifyContent="space-between" mb={3}>
                  <Box
                    border={1}
                    borderColor="grey.400"
                    borderRadius="18px"
                    p={3}
                    width="48%"
                    bgcolor="#ffffff"
                  >
                    <Typography variant="h6" fontWeight="bold" mb={1}>
                      Patient Details
                    </Typography>
                    <div>
                    <TextField
        label="ID"
        name="id"
        value={toDetails.id}
        onChange={handleIdChange} 
        fullWidth
        margin="normal"
        sx={{
          borderRadius: "15px",
          width: '275px',
          backgroundColor: "#D8F5EE",
          "& .MuiOutlinedInput-root": {
            borderRadius: "15px",
            height: "45px",
          },
        }}
      />
      <TextField
        label="Patient Name"
        name="name"
        value={toDetails.name}
        onChange={handleChange}
        fullWidth
        margin="normal"
        sx={{
          borderRadius: "15px",
          width: '275px',
          backgroundColor: "#D8F5EE",
          "& .MuiOutlinedInput-root": {
            borderRadius: "15px",
            height: "45px",
          },
        }}
      />
      <TextField
        label="Age"
        name="age"
        value={toDetails.age}
        onChange={handleChange}
        fullWidth
        margin="normal"
        sx={{
          borderRadius: "15px",
          width: '275px',
          backgroundColor: "#D8F5EE",
          "& .MuiOutlinedInput-root": {
            borderRadius: "15px",
            height: "45px",
          },
        }}
      />
      <TextField
        label="Phone Number"
        name="phone"
        value={toDetails.phone}
        onChange={handlePhoneChange} 
        fullWidth
        margin="normal"
        sx={{
          borderRadius: "15px",
          width: '275px',
          backgroundColor: "#D8F5EE",
          "& .MuiOutlinedInput-root": {
            borderRadius: "15px",
            height: "45px",
          },
        }}
      />
    </div>
                  </Box>
                  <Box
                    border={1}
                    borderColor="grey.400"
                    borderRadius="18px"
                    p={3}
                    width="48%"
                    bgcolor="#ffffff"
                  >
                    <Typography variant="h6" fontWeight="bold">Admission Details</Typography>
                    <div>
                    <TextField
        label="Date Of Admission"
        name="dateOfAdmission"
        value={toDetails.dateOfAdmission}
        onChange={handleChange} 
        fullWidth
        margin="normal"
        sx={{
          borderRadius: "15px",
          width: '275px',
          backgroundColor: "#D8F5EE",
          "& .MuiOutlinedInput-root": {
            borderRadius: "15px",
            height: "45px",
          },
        }}
      />
      <TextField
        label="Date Of Surgery"
        name="dateOfSurgery"
        value={toDetails.dateOfSurgery}
        onChange={handleChange}
        fullWidth
        margin="normal"
        sx={{
          borderRadius: "15px",
          width: '275px',
          backgroundColor: "#D8F5EE",
          "& .MuiOutlinedInput-root": {
            borderRadius: "15px",
            height: "45px",
          },
        }}
      />
      <TextField
        label="Date Of Discharge"
        name="dateOfDischarge"
        value={toDetails.dateOfDischarge}
        onChange={handleChange}
        fullWidth
        margin="normal"
        sx={{
          borderRadius: "15px",
          width: '275px',
          backgroundColor: "#D8F5EE",
          "& .MuiOutlinedInput-root": {
            borderRadius: "15px",
            height: "45px",
          },
        }}
      />
    </div>
                  </Box>
                </Box>
                <Box mb={3}>
                  <Typography variant="h6" fontWeight="bold">
                    1. Consultancy
                  </Typography>
                  <TextField
                    multiline
                    rows={3}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    sx={{ borderRadius: '5px', backgroundColor: "#F5F5F5" }}
                  />
                </Box>

                <Box mb={3}>
                  <Typography variant="h6" fontWeight="bold">
                    2. Diagnosis
                  </Typography>
                  <TextField
                    multiline
                    rows={3}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    sx={{ borderRadius: '5px', backgroundColor: "#F5F5F5", textDecoration: 'underline' }}
                  />
                </Box>

                <Box mb={3}>
                  <Typography variant="h6" fontWeight="bold">
                    3. Procedure
                  </Typography>
                  <TextField
                    multiline
                    rows={3}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    sx={{ borderRadius: '5px', backgroundColor: "#F5F5F5" }}
                  />
                </Box>

                <Box mb={3}>
                  <Typography variant="h6" fontWeight="bold">
                    4. Past History
                  </Typography>
                  <TextField
                    multiline
                    rows={3}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    sx={{ borderRadius: '5px', backgroundColor: "#F5F5F5" }}
                  />
                </Box>

                <Box mb={3}>
                  <Typography variant="h6" fontWeight="bold">
                    5. Present Complaints
                  </Typography>
                  <TextField
                    multiline
                    rows={3}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    sx={{ borderRadius: '5px', backgroundColor: "#F5F5F5" }}
                  />
                </Box>

                <Box mb={3}>
                  <Typography variant="h6" fontWeight="bold">
                    6. Investigations
                  </Typography>
                  <TextField
                    multiline
                    rows={3}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    sx={{ borderRadius: '5px', backgroundColor: "#F5F5F5" }}
                  />
                </Box>

                <Box mb={3}>
                  <Typography variant="h6" fontWeight="bold">
                    7. Local Examination
                  </Typography>
                  <TextField
                    multiline
                    rows={3}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    sx={{ borderRadius: '5px', backgroundColor: "#F5F5F5" }}
                  />
                </Box>

                <Box mb={3}>
                  <Typography variant="h6" fontWeight="bold">
                    8. X-RAY
                  </Typography>
                  <TextField
                    multiline
                    rows={3}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    sx={{ borderRadius: '5px', backgroundColor: "#F5F5F5" }}
                  />
                </Box>
                <Box mb={3}>
                  <Typography variant="h6" fontWeight="bold">
                    9. Advice At Discharge
                  </Typography>
                  <TextField
                    multiline
                    rows={3}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    sx={{ borderRadius: '5px', backgroundColor: "#F5F5F5" }}
                  />
                </Box>
                <Box mb={3}>
                  <Typography variant="h6" fontWeight="bold">
                    10. Follow-Up
                  </Typography>
                  <TextField
                    multiline
                    rows={3}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    sx={{ borderRadius: '5px', backgroundColor: "#F5F5F5" }}
                  />
                </Box>

               
                
                <Box display="flex" justifyContent="center" mt={4}>
                <Button
              onClick={handleSave}
              sx={{
                backgroundColor: 'green',
                color: '#fff',
                '&:hover': {
                  backgroundColor: 'darkgreen',
                },
                marginRight: '1rem' 
              }}
            >
              Save
            </Button>
            <Button
              onClick={() => navigate("/dischargesummary")}
              sx={{
                backgroundColor: 'red',
                color: '#fff',
                '&:hover': {
                  backgroundColor: 'darkred',
                },
              }}
            >
              Cancel
            </Button>
            </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </DashboardLayout>
  );
}

export default Invoice;
