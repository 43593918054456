import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Card, Box, Typography, Container, MenuItem } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";

const initialPatientData = {
  id: "2",
  invoiceNumber: "2344",
  city: "Devakottai",
  patientName: "Aravind",
  admissionDate: "12/07/2024",
  dischargeDate: "15/07/2024",
  phone: "9036358922",
  patientType: "OP",
  address: "21-B, Gandhisalai",
  state: "Ramanathapuram",
  country: "India",
  patientservice: "bloodtest",
  servicecharge: "1255",
  totalAmount: "12334",
  bednumber: "122",
};

const EditSummary = () => {
  const navigate = useNavigate();
  const [patientData, setPatientData] = useState(initialPatientData);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPatientData({ ...patientData, [name]: value });
  };

  const handleSave = () => {
    navigate("/dischargesummary");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container
        maxWidth="lg"
        style={{ padding: "2rem", height: "100vh", display: "flex", flexDirection: "column" }}
      >
        <MDBox py={4}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={3}
                  mt={-3}
                  py={3}
                  px={2}
                  sx={{
                    background: "linear-gradient(45deg, #712c8c, #de157a)",
                    borderRadius: "12px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6" color="#ffff" align="center">
                    Edit Discharge Summary
                  </Typography>
                </MDBox>
                <Box p={3}>
                  <Typography variant="h6" gutterBottom>
                    Patient Details
                  </Typography>
                  <Box>
                    <TextField
                      label="Patient Name"
                      name="patientName"
                      value={patientData.patientName}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="Invoice Number"
                      name="invoiceNumber"
                      value={patientData.invoiceNumber}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="Admission Date"
                      name="admissionDate"
                      value={patientData.admissionDate}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      label="Discharge Date"
                      name="dischargeDate"
                      value={patientData.dischargeDate}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      label="Phone"
                      name="phone"
                      value={patientData.phone}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="Address"
                      name="address"
                      value={patientData.address}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="City"
                      name="city"
                      value={patientData.city}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="State"
                      name="state"
                      value={patientData.state}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="Country"
                      name="country"
                      value={patientData.country}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="Patient Type"
                      name="patientType"
                      value={patientData.patientType}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      select
                      InputProps={{
                        style: { padding: "10px" },
                      }}
                    >
                      <MenuItem value="OP">Outpatient</MenuItem>
                      <MenuItem value="IP">Inpatient</MenuItem>
                    </TextField>
                    <TextField
                      label="Service Charge"
                      name="servicecharge"
                      value={patientData.servicecharge}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="Total Amount"
                      name="totalAmount"
                      value={patientData.totalAmount}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="Bed Number"
                      name="bednumber"
                      value={patientData.bednumber}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Grid>
          <Box mt={3} style={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={handleSave}
              sx={{
                backgroundColor: "green",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "darkgreen",
                },
                marginRight: "1rem",
              }}
            >
              Save
            </Button>
            <Button
              onClick={() => navigate("/dischargesummary")}
              sx={{
                backgroundColor: "red",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "darkred",
                },
              }}
            >
              Cancel
            </Button>
          </Box>
        </MDBox>
      </Container>
    </DashboardLayout>
  );
};

export default EditSummary;
