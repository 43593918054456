import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import data from 'data.json'; 
import { BASE_API_URL } from "api/Api";
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector} from "react-redux";

const EditInPatient = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [inPatient, setInPatient] = useState(null);
    const [availableDoctors, setAvailableDoctors] = useState([]);
    const [availableServices, setAvailableServices] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);
    const [inPatients, setInPatients] = useState([]);

    const sessionId = useSelector((state) => state.session.sessionId);

    // API call to fetch in-patients
    useEffect(() => {
    

        const fetchInPatients = async () => {
            const apiUrl = BASE_API_URL;
            const method = "get_entry_list";
            const input_type = "JSON";
            const response_type = "JSON";
            const rest_data = JSON.stringify({
                session: sessionId,
                module_name: "Contacts",
            });

            const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

            try {
                const response = await axios.get(fullUrl);
                console.log("API Response:", response.data);

                const getPatientsData = () => {
                    if (response.data && response.data.entry_list) {
                        return response.data.entry_list.map(entry => {
                            const data = entry.name_value_list;
                            return {
                                id: entry.id,
                                uhid: data.uhid_c.value,
                                name: data.pateintname_c.value,
                                gender: data.patient_gender_c.value,
                                patientType: data.patienttype_c.value,
                                age: data.patient_age_c.value,
                                email: data.email1.value,
                                phone: data.phone_mobile.value, 
                                city: data.city_c.value,
                                state: data.state_c.value,
                                country: data.patient_country_name_c.value,
                                admissionDate: data.admissiondate_c.value,
                                dischargedDate: data.dischargeddate_c.value,
                                services: data.servicesnames_c.value.split(',')
                                .map(item => item.trim())
                                .filter(item => item !== ''),
                                serviceCost: data.servicecost_c.value,
                                fees: data.totalcost_c.value,
                                doctors: data.doc_c.value
                                .split(',')
                                .map(item => item.trim())
                                .filter(item => item !== ''),
                            };
                        });
                    }
                    return [];
                };

                const inPatientsData = getPatientsData();
                setInPatients(inPatientsData); 
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Error fetching data");
            }
        };

        if (sessionId){
            fetchInPatients();
        }
    }, []);

    const handleDoctorChange = (event) => {
        const { value } = event.target;
        setSelectedDoctor(typeof value === 'string' ? value.split(',') : value);
      };
    const handleServiceChange = (event) => {
        const { value } = event.target;
        setSelectedServices(typeof value === 'string' ? value.split(',') : value);
      };

    // Fetch available doctors and services
    useEffect(() => {
        const {  doctors, services } = data;
        setAvailableDoctors(doctors || []);
        setAvailableServices(services || []);

        const patient = inPatients.find((patient) => patient.id === (id));
        if (patient) {
            setInPatient(patient);
            setSelectedDoctor(patient.doctors || []); 
            setSelectedServices(patient.services || []);
        }
    }, [id, inPatients]);
    

    // Handle form submission to save edited inpatient data
    const handleSaveEditedInPatient = async (event) => {
        event.preventDefault();

        // Construct the updated patient data object
        const updatedInPatient = {
            id: inPatient.id,
            assignedDoctor: selectedDoctor,
            services: selectedServices,
            uhid: inPatient.uhid,
            name: inPatient.name,
            gender: inPatient.gender,
            age: inPatient.age,
            phone: inPatient.phone,
            city: inPatient.city,
            state: inPatient.country,
            email: inPatient.email, 
            fees: inPatient.fees,
            admissionDate: inPatient.admissionDate,
            dischargedDate: inPatient.dischargedDate,
        };

        // API call to save the updated inpatient data
        const apiUrl = `${BASE_API_URL}`;
        const method = "set_entry";
        const input_type = "JSON";
        const response_type = "JSON";
        

        const rest_data = JSON.stringify({
            session: sessionId,
            module_name: "Contacts",
            name_value_list: {
                id: updatedInPatient.id,
                uhid_c: updatedInPatient.uhid,
                pateintname_c: updatedInPatient.name,
                patient_gender_c: updatedInPatient.gender,
                patient_age_c: updatedInPatient.age,
                phone_mobile: updatedInPatient.phone,  
                city_c: updatedInPatient.city,
                state_c: updatedInPatient.state,
                servicesnames_c: updatedInPatient.services.join(', '),
                servicecost_c: updatedInPatient.services.reduce((total, s) => total + s.fee, 0), 
                totalcost_c: updatedInPatient.fees,
                email1: updatedInPatient.email,
                doc_c: updatedInPatient.assignedDoctor.join(', '),
                admissiondate_c:updatedInPatient.admissionDate,
                dischargeddate_c:updatedInPatient.dischargedDate,
            },
        });

        const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

        try {
            const response = await axios.post(fullUrl);

            if (response.status === 200 && response.data.id) {
                console.log("InPatient updated successfully:", response.data);
                navigate('/inpatients');
                toast.success("Patient Updated Successfully")
            } else {
                console.error("InPatient update failed:", response.data);
                toast.error("Patient Update Failed")
            }
        } catch (error) {
            if (error.response) {
                console.error("Error during InPatient update:", error.response.data);
            } else {
                console.error("Error setting up request:", error.message);
            }
        }
    };

    if (!inPatient) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <MDBox py={4} px={3}>
                    <MDTypography variant="h5" fontWeight="bold">
                    No In Patients available at the moment
                    </MDTypography>
                </MDBox>
            </DashboardLayout>
        );
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox py={4} mx={5} px={3}>
                <MDTypography variant="h5" mb={2} gutterBottom fontWeight="bold" align="center">
                    Edit In Patients
                </MDTypography>
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={6}>
                        <MDInput
                            label="UHID"
                            value={inPatient.uhid || ''}
                            onChange={(e) => setInPatient({ ...inPatient, uhid: e.target.value })}
                            fullWidth
                        />
                        <MDInput
                            label="Name"
                            value={inPatient.name || ''}
                            onChange={(e) => setInPatient({ ...inPatient, name: e.target.value })}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                        <MDInput
                            label="Gender"
                            value={inPatient.gender || ''}
                            onChange={(e) => setInPatient({ ...inPatient, gender: e.target.value })}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                        <MDInput
                            label="Age"
                            type="number"
                            value={inPatient.age || ''}
                            onChange={(e) => setInPatient({ ...inPatient, age: e.target.value })}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                        <MDInput
                            label="Phone Number"
                            value={inPatient.phone || ''}
                            onChange={(e) => setInPatient({ ...inPatient, phone: e.target.value })}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                        <MDInput
                            label="City"
                            value={inPatient.city || ''}
                            onChange={(e) => setInPatient({ ...inPatient, city: e.target.value })}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                        <MDInput
                            label="Email"
                            value={inPatient.email || ''}
                            onChange={(e) => setInPatient({ ...inPatient, email: e.target.value })}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} mt={-1}>
                        <label htmlFor="doctor-select" className="text-sm">Doctor</label>
                        <Select
                            id="doctor-select"
                            multiple
                            value={selectedDoctor}
                            onChange={handleDoctorChange}
                            displayEmpty
                            fullWidth
                            className="p-3"
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return 'Add Doctor';
                                }
                                return selected.filter(Boolean).join(', ');
                              }}
                            sx={{ mt: 1 }}
                        >
                            {availableDoctors.map((doctor) => (
                                <MenuItem key={doctor} value={doctor}>
                                    <Checkbox checked={selectedDoctor.indexOf(doctor) > -1}/>
                                    {doctor}
                                </MenuItem>
                            ))}
                        </Select>
                        <label htmlFor="service-select" className="text-sm" style={{ marginTop: 20 }}>Services</label>
                        <Select
                            id="service-select"
                            multiple
                            value={selectedServices}
                            onChange={handleServiceChange}
                            displayEmpty
                            fullWidth
                            className="p-3"
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return 'Add Services';
                                }
                                return selected.filter(Boolean).join(', ');
                              }}
                            sx={{ mt: 1 }}
                        >
                            {availableServices.map((service) => (
                                <MenuItem key={service.name} value={service.name}>
                                    <Checkbox checked={selectedServices.indexOf(service.name) > -1} />
                                    {service.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <MDInput
                            label="Fees"
                            type="number"
                            value={inPatient.fees || ''}
                            onChange={(e) => setInPatient({ ...inPatient, fees: e.target.value })}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                        <MDInput
                            label="State"
                            
                            value={inPatient.country || ''}
                            onChange={(e) => setInPatient({ ...inPatient, country: e.target.value })}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                        <MDInput
                            label="Admission Date"
                            type="date"
                            value={inPatient.admissionDate || ''}
                            onChange={(e) => setInPatient({ ...inPatient, admissionDate: e.target.value })}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                        <MDInput
                            label="Discharged Date"
                            type="date"
                            value={inPatient.dischargedDate || ''}
                            onChange={(e) => setInPatient({ ...inPatient, dischargedDate: e.target.value })}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                    </Grid>
                </Grid>
                <MDBox display="flex" justifyContent="center" mt={4} gap={5}>
                    <MDButton
                        variant="contained"
                        color="error"
                        onClick={() => navigate('/inpatients')}
                    >
                        Cancel
                    </MDButton>
                    <MDButton variant="contained" color="success" onClick={handleSaveEditedInPatient}>
                        Save
                    </MDButton>
                </MDBox>
            </MDBox>
            <ToastContainer position="top-right" />
        </DashboardLayout>
    );
};

export default EditInPatient;
